export const userStatus = [
    { value: 12, label: 'Inactive user', key: 'In-Active' },
    { value: 3, label: 'Ban user' , key: 'Ban'},
    { value: 2, label: 'Restrict user', key: 'Restrict'},
    { value: 4, label: 'Internal user', key: "Internal-User" }
  ];


  export const userStatus2 = { 
    "Restrict": ['Unrestrict user', 2],
    "Ban": ['Un-Ban user', 3 ],
    "In-Active": ['Activate user', 12],
    "Internal-User": ["Inactive user", 12 ]
    // 4: 'External user',
  };