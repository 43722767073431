

export const tableHeaders = [
    { labelKey: 'Promocode Id', value: 'crmPromotionId' },
    { labelKey: 'Promocode', value: 'promocode' },
    { labelKey: 'Name', value: 'name' },
    { labelKey: 'Is Active', value: 'isActive' },
    { labelKey: 'Promotion Type', value: 'promotionType' },
    { labelKey: 'SC Amount', value: 'scAmount' },
    { labelKey: 'GC Amount', value: 'gcAmount' },
    { labelKey: 'Action', value: 'Action' },
];


export const viewPromocodeHeaders = [
    { labelKey: 'CRM Promotion Id', value: 'crmPromotionId' },
    { labelKey: 'Promo Code', value: 'promoCode' },
    { labelKey: 'Name', value: 'name' },
    { labelKey: 'Promotion Type', value: 'promotionType' },
    { labelKey: 'Claimed Count', value: 'claimedCount' },
    { labelKey: 'Status', value: 'isActive' },
    { labelKey: 'Start Date', value: 'startDate' },
    { labelKey: 'End Date', value: 'endDate' },
    { labelKey: 'Deleted Date', value: 'deletedAt' },
    { labelKey: 'Action', value: 'Action' },

  ];
  
  export const viewUserDetailsHeaders = [
    { labelKey: 'User Id', value: 'userId' },
    { labelKey: 'Username', value: 'username' },
    { labelKey: 'Email', value: 'email' },
    { labelKey: 'Name', value: 'firstName' },
    { labelKey: 'Last Name', value: 'lastName' },
    { labelKey: 'Status', value: 'isActive' },
    { labelKey: 'Claimed At', value: 'claimedAt' },]

  export const CRM_PROMOTION_TYPE = [
      {label: 'All', value:''},
      {label: 'Scheduled', value: 'scheduled-campaign'},
      {label: 'Triggered', value: 'triggered-campaign'}
  ]