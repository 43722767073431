import React from 'react'
import useTournamentDetails from '../hooks/useTournamentDetails'
import CreateTournament from './CreateTournament'
import Preloader from '../../../components/Preloader'

const EditTournament = () => {
  const { tournamentData,
    loading, refetchTournament, page, setPage,  totalPages, limit, setLimit, getCsvDownloadUrl } = useTournamentDetails()

  if (loading) return <Preloader />
  return <CreateTournament data={tournamentData} details refetchTournament={refetchTournament} page={page} setPage={setPage}
  totalPages={totalPages} limit={limit} setLimit={setLimit} getCsvDownloadUrl={getCsvDownloadUrl} />
}

export default EditTournament
