import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { casinoCategorySchema } from "../schemas";
import { serialize } from "object-to-formdata";
import { FaTimes } from "react-icons/fa";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  ProgressBar,
  Tabs,
  Tab,
  Accordion,
} from "@themesberg/react-bootstrap";
import useCreateTournaments from "../hooks/useCreateTournaments";
import {
  convertToUTC,
  getDateTime,
  getDateTimeByYMD,
} from "../../../utils/dateFormatter";
import Datetime from "react-datetime";
import { toast } from "../../../components/Toast";
import LeaderBoard from "./LeaderBoard";
import RankPercentSelector from "./RankPercentSelector";
import { isEqual, pickBy } from "lodash";
import AddGamesForm from "./AddGamesForm";
import TournamentDashboard from "./TournamentDashboard";
import TournamentPayout from "./TournamentPayout";
import { useNavigate } from "react-router-dom";
import { AdminRoutes } from "../../../routes";
import useProviderSubcategoryFilter from "../hooks/useProviderSubcategoryFIlter";
import useTournamentDashboardDetails from "../hooks/useTournamentDashboardDetails";
import usePayoutTournamentDetails from "../hooks/usePayoutTournamentDetails";

const TOURNAMENT_STATUS = {
  UPCOMING: 0,
  ON_GOING: 1,
  COMPLETED: 2,
  CANCELLED: 3,
};

const CreateTournament = ({
  data,
  details,
  refetchTournament,
  page,
  setPage,
  totalPages,
  limit,
  setLimit,
  getCsvDownloadUrl,
  type
}) => {
  const navigate = useNavigate();

  const { t, loading, updateTournament, createTournamentList } =
    useCreateTournaments();

  const { tournamentDashboardData = {} } = useTournamentDashboardDetails({ type });

  const tournamentData = data?.tournament || null;

  const { payoutTournamentList, refetch } = usePayoutTournamentDetails({type, tournamentData})

  const [formData, setFormData] = useState({
    title: tournamentData ? tournamentData.title : "",
    entryAmount: tournamentData ? tournamentData.entryAmount : "",
    endDate: tournamentData
      ? tournamentData.endDate
        ? getDateTime(tournamentData.endDate)
        : ""
      : "",
    startDate: tournamentData
      ? tournamentData.startDate
        ? getDateTime(tournamentData.startDate)
        : ""
      : "",
    playerLimit: tournamentData ? tournamentData.playerLimit : "",
    winSc: tournamentData ? tournamentData.winSc : "",
    winGc: tournamentData ? tournamentData.winGc : "",
    gameId: tournamentData
      ? tournamentData?.games?.length > 0 &&
      tournamentData?.games?.map((item) => {
        return Number(item.masterCasinoGameId);
      })
      : [],
    description: tournamentData ? tournamentData.description : "",
    entryCoin: tournamentData ? tournamentData.entryCoin : "SC",
    winnerPercentage: tournamentData ? tournamentData.winnerPercentage : [],
    numberOfWinners: tournamentData
      ? tournamentData?.winnerPercentage?.length
      : "",
    playerLimitIsActive: tournamentData
      ? tournamentData?.playerLimit
        ? true
        : false
      : false,
    tournamentImg: tournamentData ? tournamentData?.tournamentImg : "",
  });

  const [formattedTournamentData, setFormattedTournamentData] = useState({});
  // Thumbnail Image
  const [image, setImage] = useState(null);
  const [imageDimensionError, setImageDimensionError] = useState(null);

  const fileInputRef = useRef();

  useEffect(() => {
    if (tournamentData) {
      let formattedData = {
        title: tournamentData ? tournamentData.title : "",
        entryAmount: tournamentData ? tournamentData.entryAmount : "",
        endDate: tournamentData.endDate
          ? getDateTime(tournamentData.endDate)
          : "",
        startDate: tournamentData.startDate
          ? getDateTime(tournamentData.startDate)
          : "",
        playerLimit: tournamentData ? tournamentData.playerLimit : "",
        winSc: tournamentData ? tournamentData.winSc : "",
        winGc: tournamentData ? tournamentData.winGc : "",
        gameId: tournamentData
          ? tournamentData?.games?.length > 0 &&
          tournamentData?.games?.map((item) => {
            return Number(item.masterCasinoGameId);
          })
          : [],
        description: tournamentData ? tournamentData.description : "",
        entryCoin: tournamentData ? tournamentData.entryCoin : "SC",
        winnerPercentage: tournamentData ? tournamentData.winnerPercentage : [],
        numberOfWinners: tournamentData
          ? tournamentData?.winnerPercentage?.length
          : "",
        playerLimitIsActive: tournamentData
          ? tournamentData?.playerLimit
            ? true
            : false
          : false,
        tournamentImg: tournamentData ? tournamentData?.tournamentImg : "",
      };
      setFormData(formattedData);
      setFormattedTournamentData(formattedData);
    }
  }, [tournamentData]);

  const tournamentLeaderBoard = data?.tournamentLeaderBoard || [];
  const yesterday = new Date(Date.now() - 86400000);

  const { allProviders, subCategories } = useProviderSubcategoryFilter({details});

  const [activeAccordion, setActiveAccordion] = useState({});

  const formErrors = useRef({});

  const checkErrorsInAccordion = (errors) => {
    const KEY_MAP = {
      BASIC_DETAILS: [
        "description",
        "title",
        "tournamentImg",
        "startDate",
        "endDate",
      ],
      AMOUNT_DETAILS: ["entryAmount", "winSc", "winGc"],
      PLAYER_DETAILS: ["playerLimit"],
      GAMES_DETAILS: ["gameId"],
      WINNERS_DETAILS: ["numberOfWinners"],
    };

    let updatedAccordionState = {};

    Object.keys(KEY_MAP).forEach((key) => {
      const accordionFormKeys = KEY_MAP[key];
      let isErrorExistInAccordion = false;
      accordionFormKeys.every((formKey) => {
        if (errors[formKey]) {
          isErrorExistInAccordion = true;
        }
        return !isErrorExistInAccordion;
      });
      updatedAccordionState = {
        ...updatedAccordionState,
        [key]: isErrorExistInAccordion,
      };
    });
    setActiveAccordion(updatedAccordionState);
    return updatedAccordionState;
  };

  const ACCORDION_CONSTANTS = {
    BASIC_DETAILS: "BASIC_DETAILS",
    AMOUNT_DETAILS: "AMOUNT_DETAILS",
    WINNERS_DETAILS: "WINNERS_DETAILS",
    GAMES_DETAILS: "GAMES_DETAILS",
    PLAYER_DETAILS: "PLAYER_DETAILS",
  };

  const handleToggleAccordian = (accordionKey) => {
    if (activeAccordion[accordionKey]) {
      setActiveAccordion({
        ...activeAccordion,
        [accordionKey]: false,
      });
    } else {
      setActiveAccordion({
        ...activeAccordion,
        [accordionKey]: true,
      });
    }
  };

  function WithLabelExample(info) {
    let sum = info?.reduce((acc, o) => acc + parseFloat(o), 0);
    sum = Math.round(sum);
    return <ProgressBar now={sum} label={`${sum}%`} />;
  }

  // Thumbnail Image
  const handleFileChange = (event, setFieldValue, field) => {
    const file = event.currentTarget.files[0];
    setImage(file);
    // Validate file dimensions
    validateFileDimensions(file, field);

    // Optionally, you can update form field value
    setFieldValue(field, file);
  };

  const handleCancelImage = (setFieldValue) => {
    setFieldValue("tournamentImg", null);
    fileInputRef.current.value = null;
  };

  const validateFileDimensions = (file, field) => {
    if (file) {
      const img = new Image();
      img.onload = function () {
        if (img.width === 582 && img.height === 314) {
          setImageDimensionError(false);
          setImage(null);
        } else {
          setImageDimensionError(true);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  return (
    <>
      
      <Row>
        <Col sm={12}>
          <h3>
            {tournamentData ? (details ? "View" : "Edit") : "Create"}{" "}
            {t("tournaments.createCategory.label")}
          </h3>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={
          tournamentData ? (details ? "Dashboard" : "Edit") : "Create"
        }
        id="justify-tab-example"
        className={`${tournamentData && details ? "mt-5 ms-2" : "mt-2"} m-3`}
      // justify
      >
        {tournamentData && details && (
        <Tab eventKey="Dashboard" title="Dashboard">
          <TournamentDashboard
            tournamentData={tournamentData}
            tournamentDashboardData={tournamentDashboardData}
          />
        </Tab>
      )}
      {tournamentData && details && (
        <Tab eventKey="LeaderBoard" title="LeaderBoard">
          <LeaderBoard
            list={tournamentLeaderBoard}
            tournamentData={tournamentData}
            refetchTournament={refetchTournament}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            limit={limit}
            setLimit={setLimit}
            getCsvDownloadUrl={getCsvDownloadUrl}
            payoutTournamentList={payoutTournamentList} 
          />
        </Tab>
      )}
        <Tab
          eventKey={tournamentData ? (details ? "View" : "Edit") : "Create"}
          title={tournamentData ? (details ? "View" : null) : null}
        >
          <Formik
            enableReinitialize
            initialValues={formData}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={casinoCategorySchema(
              t,
              tournamentData,
              TOURNAMENT_STATUS
            )}
            validate={(data) => { }}
            onSubmit={(formValues) => {

              // Get the number of winners from form values
              const numberOfWinners = formValues.numberOfWinners;

              const playerCountFromRankPercentSelector = formValues?.winnerPercentage?.length;

              let updatedAccordionState = { ...activeAccordion };

              if (Number(numberOfWinners) !== playerCountFromRankPercentSelector) {
                toast(`Number of winners should match the player count in the Rank Percent Selector.`, 'error');
                updatedAccordionState[ACCORDION_CONSTANTS.WINNERS_DETAILS] = true;
                setActiveAccordion(updatedAccordionState);
                return;
              }

              let sum = formValues?.winnerPercentage?.length > 0 && formValues?.winnerPercentage?.reduce((acc, o) => acc + parseFloat(o), 0);
              sum = sum && Math.round(sum);
              if (sum !== 100) {
                toast(
                  "Sum of all winner player percentage should be 100",
                  "error"
                );
                return null;
              }
              let data = {
                title: formValues.title,
                entryAmount: formValues.entryAmount,
                endDate: convertToUTC(formValues.endDate),
                startDate: convertToUTC(formValues.startDate),
                playerLimit: formValues?.playerLimitIsActive
                  ? formValues?.playerLimit
                    ? formValues.playerLimit
                    : null
                  : null,
                winSc: formValues.winSc,
                winGc: formValues.winGc,
                gameId: formValues?.gameId || [],
                description: formValues.description,
                entryCoin: formValues.entryCoin,
                winnerPercentage: formValues?.winnerPercentage || [],
                tournamentImg: formValues.tournamentImg,
              };

              if (tournamentData) {
                const changedData = pickBy(data, (value, key) => {
                  if (key === "endDate" || key === "startDate") {
                    return !isEqual(
                      moment(value).format("YYYY-MM-DD hh:mm:ss"),
                      moment(formattedTournamentData[key]).format(
                        "YYYY-MM-DD hh:mm:ss"
                      )
                    );
                  }
                  return !isEqual(value, formattedTournamentData[key]);
                });

                if (Object.keys(changedData).length) {
                  updateTournament(
                    serialize({
                      ...changedData,
                      tournamentId: tournamentData?.tournamentId,
                    })
                  );
                  // const fData = new FormData()
                  // Object.keys(changedData).forEach((key) => {
                  //   if (changedData[key] && Array.isArray(changedData[key])) {
                  //     changedData[key]?.forEach((val) => {
                  //       fData.append(key, val)
                  //     })
                  //   } else {
                  //     fData.append(key, changedData[key])
                  //   }
                  // })
                  // fData.append('tournamentId', tournamentData?.tournamentId)
                  // updateTournament(fData)
                } else {
                  toast("No changes available to update.", "error");
                }
              } else {
                createTournamentList(serialize(data));
              }
            }}
          >
            {(props) => {
              const {
                values,
                handleChange,
                handleSubmit,
                errors = {},
                handleBlur,
                setFieldValue,
                setFieldTouched,
                setErrors,
              } = props;

              if (
                !isEqual(errors, formErrors.current) &&
                !Object.keys(formErrors.current).length
              ) {
                formErrors.current = errors;
                checkErrorsInAccordion(errors);
              }

              return (
                <Form>
                  {/* Basic Details Accordion */}
                  <Accordion
                    activeKey={
                      activeAccordion[ACCORDION_CONSTANTS.BASIC_DETAILS]
                        ? ACCORDION_CONSTANTS.BASIC_DETAILS
                        : ""
                    }
                  >
                    <Accordion.Item
                      className="mb-3"
                      eventKey={ACCORDION_CONSTANTS.BASIC_DETAILS}
                    >
                      <Accordion.Header
                        onClick={() =>
                          handleToggleAccordian(
                            ACCORDION_CONSTANTS.BASIC_DETAILS
                          )
                        }
                      >
                        <Row className="" style={{ cursor: "pointer" }}>
                          <h5 className="accordian-heading mb-0">
                            <span>Basic Details</span>
                          </h5>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-0">
                          <Col md={12} sm={12} className="mt-3">
                            {/* Category Name Form */}
                            <div className="">
                              <BForm.Label>
                                {t("tournaments.inputField.categoryName.label")}
                                <span className="text-danger"> *</span>
                              </BForm.Label>
                              <BForm.Control
                                type="text"
                                name="title"
                                placeholder={t(
                                  "tournaments.inputField.categoryName.placeholder"
                                )}
                                value={values?.title}
                                onChange={(e) => {
                                  const val = e?.target?.value?.trim()
                                    ? e.target.value
                                    : "";
                                  setFieldValue("title", val);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setFieldTouched("title", true);
                                }} // Ensure field is touched
                                autoComplete="off"
                                disabled={details}
                              />
                              <ErrorMessage
                                component="div"
                                name="title"
                                className="text-danger"
                              />
                            </div>

                            {/* Description Form */}
                            <div className="mt-4">
                              <BForm.Label>
                                {t("tournaments.inputField.description.label")}
                                <span className="text-danger"> *</span>
                              </BForm.Label>
                              <BForm.Control
                                type="text"
                                as="textarea"
                                rows="3"
                                name="description"
                                placeholder={t(
                                  "tournaments.inputField.description.placeholder"
                                )}
                                value={values?.description || ""}
                                onChange={(e) => {
                                  const val = e?.target?.value?.trim()
                                    ? e.target.value
                                    : "";
                                  setFieldValue("description", val);
                                }}
                                onBlur={handleBlur}
                                disabled={details}
                              />
                              <ErrorMessage
                                component="div"
                                name="description"
                                className="text-danger"
                              />
                            </div>

                            {/* Thumbnail Form */}
                            <div className="">
                              <BForm.Label>Thumbnail</BForm.Label>
                              <BForm.Control
                                ref={fileInputRef}
                                accept="image/jpg, image/jpeg, image/png, image/svg+xml, image/webp"
                                type="file"
                                name="tournamentImg"
                                onChange={(event) =>
                                  handleFileChange(
                                    event,
                                    setFieldValue,
                                    "tournamentImg"
                                  )
                                }
                                disabled={details}
                              />
                              {values?.tournamentImg && (
                                <div className="mt-2">
                                  <img
                                    src={URL.createObjectURL(
                                      values?.tournamentImg
                                    )}
                                    alt="Not found"
                                    style={{
                                      width: "200px",
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <Button
                                    onClick={() => {
                                      handleCancelImage(setFieldValue);
                                    }}
                                  >
                                    {" "}
                                    cancel
                                  </Button>
                                </div>
                              )}

                              {!values?.tournamentImg &&
                                tournamentData?.imageUrl && (
                                  <img
                                    alt="not found"
                                    style={{
                                      width: "200px",
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                    src={tournamentData?.imageUrl}
                                  />
                                )}

                              {imageDimensionError ? (
                                <small style={{ color: "red" }}>
                                  Image dimensions must be equal to 582*314
                                  pixels.
                                </small>
                              ) : (
                                <ErrorMessage
                                  component="div"
                                  name="tournamentImg"
                                  className="text-danger"
                                />
                              )}
                            </div>
                          </Col>

                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label>
                              {t("tournaments.inputField.startDate")}{" "}
                              <span className="text-danger">*</span>
                            </BForm.Label>
                            <Datetime
                              inputProps={{
                                placeholder: "MM-DD-YYYY HH:MM",
                                disabled:
                                  details ||
                                  String(tournamentData?.status) ===
                                  String(TOURNAMENT_STATUS.ON_GOING),
                              }}
                              dateFormat="MM-DD-YYYY"
                              onChange={(e) => {
                                setFieldValue("startDate", e);
                              }}
                              value={
                                values?.startDate
                                // ? getDateTime(values?.startDate)
                                // : values?.startDate
                              }
                              isValidDate={(e) => {
                                return (
                                  e._d > yesterday ||
                                  getDateTimeByYMD(e._d) ===
                                  getDateTimeByYMD(new Date())
                                );
                              }}
                              timeFormat={true}
                            />
                            <ErrorMessage
                              component="div"
                              name="startDate"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label>
                              {t("tournaments.inputField.endDate")}{" "}
                              <span className="text-danger">*</span>
                            </BForm.Label>
                            <Datetime
                              inputProps={{
                                placeholder: "MM-DD-YYYY HH:MM",
                                disabled: details,
                              }}
                              dateFormat="MM-DD-YYYY"
                              onChange={(e) => {
                                setFieldValue("endDate", e);
                              }}
                              value={
                                values?.endDate
                                  ? getDateTime(values?.endDate)
                                  : values?.endDate
                              }
                              isValidDate={(e) => {
                                return (
                                  e._d > yesterday ||
                                  getDateTimeByYMD(e._d) ===
                                  getDateTimeByYMD(new Date())
                                );
                              }}
                              timeFormat={true}
                            />
                            <ErrorMessage
                              component="div"
                              name="endDate"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {/* Amount Details Accordion */}
                  <Accordion
                    activeKey={
                      activeAccordion[ACCORDION_CONSTANTS.AMOUNT_DETAILS]
                        ? ACCORDION_CONSTANTS.AMOUNT_DETAILS
                        : ""
                    }
                  >
                    <Accordion.Item
                      className="mb-3"
                      eventKey={ACCORDION_CONSTANTS.AMOUNT_DETAILS}
                    >
                      <Accordion.Header
                        onClick={() =>
                          handleToggleAccordian(
                            ACCORDION_CONSTANTS.AMOUNT_DETAILS
                          )
                        }
                      >
                        <Row className="" style={{ cursor: "pointer" }}>
                          <h5 className="accordian-heading mb-0">
                            <span>Amount Section</span>
                          </h5>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-0">
                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label>
                              {t("tournaments.inputField.entryAmount.label")}
                              <span className="text-danger"> *</span>
                            </BForm.Label>

                            <BForm.Control
                              type="number"
                              name="entryAmount"
                              min="0"
                              placeholder={t(
                                "tournaments.inputField.entryAmount.placeholder"
                              )}
                              value={values?.entryAmount}
                              onChange={(event) => {
                                const value = event.target.value;

                                const updatedValue = value.match(/^(\d+(\.\d{0,2})?)?$/) ? value : values?.entryAmount || "";
                                handleChange({
                                  target: { name: event.target.name, value: updatedValue },
                                });
                              }}
                              onBlur={handleBlur}
                              disabled={details}
                            // disabled={isEdit}
                            />

                            <ErrorMessage
                              component="div"
                              name="entryAmount"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label style={{ minWidth: "108px" }}>
                              Joining Coin Type
                              <span className="text-danger"> *</span>
                            </BForm.Label>
                            <BForm.Select
                              type="text"
                              name={"entryCoin"}
                              value={values?.entryCoin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={details}
                            >
                              {
                                <>
                                  <option key={"SC"} value={"SC"}>
                                    SC
                                  </option>
                                  <option key={"GC"} value={"GC"}>
                                    GC
                                  </option>
                                </>
                              }
                            </BForm.Select>
                          </Col>

                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label>
                              {t("tournaments.inputField.winSc.label")}
                              <span className="text-danger"> *</span>
                            </BForm.Label>

                            <BForm.Control
                              type="number"
                              name="winSc"
                              min="0"
                              placeholder={t(
                                "tournaments.inputField.winSc.placeholder"
                              )}
                              value={values?.winSc}
                              onChange={(event) => {
                                const value = event.target.value;

                                const updateValue = value.match(/^(\d+(\.\d{0,2})?)?$/) ? value : values?.winSc || "";
                                handleChange({
                                  target: { name: event.target.name, value: updateValue },
                                });
                              }}
                              onBlur={handleBlur}
                              disabled={details}
                            />

                            <ErrorMessage
                              component="div"
                              name="winSc"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label>
                              {t("tournaments.inputField.winGc.label")}
                              <span className="text-danger"> *</span>
                            </BForm.Label>

                            <BForm.Control
                              type="number"
                              name="winGc"
                              min="1"
                              placeholder={t(
                                "tournaments.inputField.winGc.placeholder"
                              )}
                              value={values?.winGc}
                              onChange={(event) => {
                                const value = event.target.value;

                                const updateValue = value.match(/^(\d+(\.\d{0,2})?)?$/) ? value : values?.winGc || "";

                                handleChange({
                                  target: { name: event.target.name, value: updateValue },
                                });
                              }}
                              onBlur={handleBlur}
                              disabled={details}
                            />

                            <ErrorMessage
                              component="div"
                              name="winGc"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {/* Player Limit Accordion */}
                  <Accordion
                    activeKey={
                      activeAccordion[ACCORDION_CONSTANTS.PLAYER_DETAILS]
                        ? ACCORDION_CONSTANTS.PLAYER_DETAILS
                        : ""
                    }
                  >
                    <Accordion.Item
                      className="mb-3"
                      eventKey={ACCORDION_CONSTANTS.PLAYER_DETAILS}
                    >
                      <Accordion.Header
                        onClick={() =>
                          handleToggleAccordian(
                            ACCORDION_CONSTANTS.PLAYER_DETAILS
                          )
                        }
                      >
                        <Row className="" style={{ cursor: "pointer" }}>
                          <h5 className="accordian-heading mb-0">
                            <span>Player Limit</span>
                          </h5>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-0">
                          {/* Player Limit */}
                          <Col md={3} sm={6} className="d-flex mt-3">
                            <BForm.Label>
                              {"Enable Player Limit"}
                              {/* <span className='text-danger'> *</span> */}
                            </BForm.Label>

                            <BForm.Check
                              type="switch"
                              name="playerLimitIsActive"
                              className="ms-6"
                              // placeholder='Enter Ac'
                              checked={values?.playerLimitIsActive}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={details}
                            />

                            <ErrorMessage
                              component="div"
                              name="playerLimitIsActive"
                              className="text-danger"
                            />
                          </Col>
                          {values?.playerLimitIsActive && (
                            <Col md={6} sm={12} className="mt-3">
                              <BForm.Label>
                                {t("tournaments.inputField.playerLimit.label")}
                                {values?.playerLimitIsActive && (
                                  <span className="text-danger"> *</span>
                                )}
                              </BForm.Label>

                              <BForm.Control
                                type="number"
                                name="playerLimit"
                                min="1"
                                onKeyDown={(e) => {
                                  if (["e", ".", "-"].includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t(
                                  "tournaments.inputField.playerLimit.placeholder"
                                )}
                                value={values?.playerLimit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={details}
                              />

                              <ErrorMessage
                                component="div"
                                name="playerLimit"
                                className="text-danger"
                              />
                            </Col>
                          )}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {/* Games Accordion */}
                  <Accordion
                    activeKey={
                      activeAccordion[ACCORDION_CONSTANTS.GAMES_DETAILS]
                        ? ACCORDION_CONSTANTS.GAMES_DETAILS
                        : ""
                    }
                  >
                    <Accordion.Item
                      className="mb-3"
                      eventKey={ACCORDION_CONSTANTS.GAMES_DETAILS}
                    >
                      <Accordion.Header
                        onClick={() =>
                          handleToggleAccordian(
                            ACCORDION_CONSTANTS.GAMES_DETAILS
                          )
                        }
                      >
                        <Row className="" style={{ cursor: "pointer" }}>
                          <h5 className="accordian-heading mb-0">
                            <span>Games</span>
                          </h5>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-0">
                          <AddGamesForm
                            providerList={allProviders?.rows}
                            subCategoryList={subCategories?.rows}
                            selectedGames={values?.gameId}
                            setSelectedGames={(selectedGames) => {
                              setFieldValue("gameId", selectedGames || []);
                            }}
                            isViewMode={details}
                            disabled={details}
                            tournamentData={tournamentData}
                          />

                          {errors?.gameId && (
                            <div style={{ color: "red" }}>
                              {errors?.gameId}{" "}
                            </div>
                          )}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  {/* Number of Winners Accordion */}
                  <Accordion
                    activeKey={
                      activeAccordion[ACCORDION_CONSTANTS.WINNERS_DETAILS]
                        ? ACCORDION_CONSTANTS.WINNERS_DETAILS
                        : ""
                    }
                  >
                    <Accordion.Item
                      className="mb-3"
                      eventKey={ACCORDION_CONSTANTS.WINNERS_DETAILS}
                    >
                      <Accordion.Header
                        onClick={() =>
                          handleToggleAccordian(
                            ACCORDION_CONSTANTS.WINNERS_DETAILS
                          )
                        }
                      >
                        <Row className="" style={{ cursor: "pointer" }}>
                          <h5 className="accordian-heading mb-0">
                            <span>Number of Winners</span>
                          </h5>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="mt-0">
                          {/* Number of Winners */}
                          <Col md={6} sm={12} className="mt-3">
                            <BForm.Label>
                              {t(
                                "tournaments.inputField.numberOfWinners.label"
                              )}
                              <span className="text-danger"> *</span>
                            </BForm.Label>

                            <BForm.Control
                              type="number"
                              name="numberOfWinners"
                              min="1"
                              placeholder={t(
                                "tournaments.inputField.numberOfWinners.placeholder"
                              )}
                              value={values?.numberOfWinners}
                              onKeyDown={(e) => {
                                if (["e", ".", "-"].includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setFieldValue(
                                  "numberOfWinners",
                                  e.target.value
                                );
                              }}
                              onBlur={handleBlur}
                              disabled={details}
                            />

                            <ErrorMessage
                              component="div"
                              name="numberOfWinners"
                              className="text-danger"
                            />
                          </Col>

                          <Row className="mt-3">
                            <Col md={6} sm={12}>
                              {values?.winnerPercentage?.length > 0 &&
                                WithLabelExample(values?.winnerPercentage)}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <FieldArray
                              name="winnerPercentage"
                              render={({ push }) => {
                                return (
                                  <div>
                                    {values?.numberOfWinners && (
                                      <Col
                                        md={12}
                                        sm={12}
                                        className="mt-3"
                                        key={`winnerPercentage-key`}
                                      >
                                        <RankPercentSelector
                                          isDisabled={details}
                                          tournamentData={tournamentData}
                                          setFieldValue={setFieldValue}
                                          numberOfWinners={
                                            values.numberOfWinners
                                          }
                                        />
                                      </Col>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          </Row>
                          {!Array.isArray(errors?.winnerPercentage) &&
                            !errors?.numberOfWinners &&
                            errors?.winnerPercentage && (
                              <ErrorMessage
                                component="div"
                                name={`winnerPercentage`}
                                className="text-danger"
                              />
                            )}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="w-100 d-flex justify-content-end gap-2 mt-4">
                    <Button
                      variant="warning"
                      onClick={() => navigate(AdminRoutes.Tournament)}
                    >
                      Cancel
                    </Button>
                    <Button
                      hidden={details}
                      variant="success"
                      onClick={(event) => {
                        handleSubmit(event);
                        checkErrorsInAccordion(errors);
                        formErrors.current = errors;
                      }}
                      className="ml-2"
                      disabled={loading}
                    >
                      {t("tournaments.createCategory.submit")}
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Tab>

        {tournamentData &&
          details &&
          (tournamentData?.status === "2" ||
            tournamentData?.status === "3") && (
            <Tab eventKey="PayoutTournament" title="Payout">
              <TournamentPayout
                list={tournamentLeaderBoard}
                tournamentData={tournamentData}
                payoutTournamentList={payoutTournamentList} 
                refetch={refetch}
              />
            </Tab>
          )}
      </Tabs>
    </>
  );
};

export default CreateTournament;
