import { Button, Col, Row, Form } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import useAddTournamentGames from "../hooks/useAddTournamentGames";
import Trigger from "../../../components/OverlayTrigger";
import GamesTournamentList from "./GamesTournamentList";

const generateProviderFilterOptions = (list) => {
    return list.map((data) => ({ label: data?.name, value: data?.masterCasinoProviderId }))
}

const generateSubCategoryOptions = (list) => {
    return list.map((data) => ({ label: data?.name?.EN, value: data?.masterGameSubCategoryId }))
}

const AddGamesForm = ({
    providerList = [], subCategoryList = [], setSelectedGames,
    selectedGames, disabled, isViewMode, tournamentData
}) => {
    const [providerOptions, setProviderOptions] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const {
        setLimit,
        setPage,
        totalPages,
        limit,
        page,
        GamesData,
        search,
        setSearch,
        resetFilters,
        providerFilter,
        setProviderFilter,
        subCategoryFilter,
        setSubCategoryFilter
    } = useAddTournamentGames({setSelectedGames, isViewMode});


    useEffect(() => {
        if (providerOptions?.length !== providerList?.length) {
            const updatedProviderOptions = generateProviderFilterOptions(providerList);
            setProviderOptions([{ label: 'All', value: 'all' }, ...updatedProviderOptions])
        }

        if (subCategoryOptions?.length !== subCategoryList?.length) {
            const updatedSubCategoryOptions = generateSubCategoryOptions(subCategoryList);
            setSubCategoryOptions([{ label: 'All', value: 'all' }, ...updatedSubCategoryOptions])
        }

    }, [providerList.length, subCategoryList.length])

    const handleSelectGames = (selectedGameId) => {
        if (selectedGames?.includes(selectedGameId)) {
            const updatedGames = selectedGames.filter(id => id !== selectedGameId);
            setSelectedGames(updatedGames);
        } else {
            const updatedGames = [...selectedGames, selectedGameId];
            setSelectedGames(updatedGames);
        }
    }

    return (
        <>
            {isViewMode ? '' :
                <Row className="w-100 m-auto">
                    <Col xs="12" sm="6" lg="3" className="mb-3">
                        <Form.Label
                            style={{
                                marginBottom: "0",
                                marginRight: "15px",
                                marginTop: "5px",
                            }}
                        >
                            {"Search"}
                        </Form.Label>

                        <Form.Control
                            type="search"
                            value={search}
                            placeholder="Type game name here"
                            onChange={(event) => {
                                setPage(1);
                                setSearch(event.target.value.replace(/[~`%^#)()><?]+/g, ""));
                            }}
                            disabled={disabled}
                        />
                    </Col>

                    <Col xs='12' lg='auto'>
                        <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
                            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                                {"Provider"}
                            </Form.Label>

                            <Form.Select
                                value={providerFilter}
                                onChange={(e) => {
                                    setPage(1);
                                    setProviderFilter(e.target.value);
                                }}
                                disabled={disabled}
                            >
                                {providerOptions?.map(({ label, value }) => {
                                    return (
                                        <option key={label} value={value}>
                                            {label}
                                        </option>
                                    );
                                })}

                            </Form.Select>
                        </div>
                    </Col>


                    <Col xs='12' lg='auto'>
                        <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
                            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                                {"Sub-Category"}
                            </Form.Label>

                            <Form.Select
                                value={subCategoryFilter}
                                onChange={(e) => {
                                    setPage(1);
                                    setSubCategoryFilter(e.target.value);
                                }}
                                disabled={disabled}
                            >
                                {subCategoryOptions?.map(({ label, value }) => {
                                    return (
                                        <option key={label} value={value}>
                                            {label}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </div>
                    </Col>

                    <Col
                        xs="12"
                        sm="6"
                        lg="1"
                        className="d-flex align-items-end mt-2 mt-sm-0 mb-0"
                    >
                        <Trigger message="Reset Filters" id={"redo"} />
                        <Button
                            id={"redo"}
                            variant="success"
                            onClick={resetFilters}
                            disabled={disabled}
                            style={{
                                position: "relative",
                                top: "-14px"
                            }}

                        >
                            <FontAwesomeIcon icon={faRedoAlt} />
                        </Button>
                    </Col>
                </Row>
            }
            <GamesTournamentList
                page={page}
                setLimit={setLimit}
                limit={limit}
                setPage={setPage}
                totalPages={totalPages}
                data={GamesData}
                handleSelectGames={handleSelectGames}
                selectedGames={selectedGames}
                disabled={disabled}
                isViewMode={isViewMode}
                tournamentData={tournamentData}
            />
        </>
    );
};

export default AddGamesForm;
