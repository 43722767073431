import { Card } from '@themesberg/react-bootstrap'
import React from 'react'
import RouteWithSidebar from '../RouteWithSidebar'
import useAffiliatePrivateRoute from './useAffiliatePrivateRoute'
import { Navigate, useNavigate } from 'react-router-dom'
import { AdminRoutes, AffiliateRoute } from '../../routes'
import { useEffect } from 'react'
import { getLoginToken } from '../../utils/storageUtils'
import { useUserStore } from '../../store/store'
import AffiliateRouteWithSidebar from '../AffiliateRouteWithSidebar'

const AffiliatePrivateRoute = ({ isWithoutCard = false, children, module }) => {
  const { userDetails, loading, permissions } = useAffiliatePrivateRoute()
  const navigate = useNavigate()
  const isUserAffiliate = useUserStore((state) => state.isUserAffiliate)

  useEffect(() => {
    if (!getLoginToken()) navigate(AffiliateRoute.AffiliateSignIn)
  }, [getLoginToken()])
  if (loading) {
    return <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>{"...loading"}</div>
  }
  return (
    userDetails && !loading ?
      <AffiliateRouteWithSidebar key={children}>
        {isWithoutCard
          ? children
          : <Card className='p-2'>{children}</Card>}
      </AffiliateRouteWithSidebar>
      : <Navigate replace to={AffiliateRoute.AffiliateSignIn} />
  )
}

export default AffiliatePrivateRoute
