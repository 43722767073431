// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ril-inner.ril__inner {
    border: 1px dashed #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: #ffffff3d;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    overflow: hidden;
    width: 340px;
    height: 480px;
}


.ril__image{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageViewer/style.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,mBAAmB;IACnB,qBAAqB;IACrB,mCAA2B;YAA3B,2BAA2B;IAC3B,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;;AAGA;IACI,WAAW;AACf","sourcesContent":[".ril-inner.ril__inner {\n    border: 1px dashed #fff;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    border-radius: 10px;\n    background: #ffffff3d;\n    backdrop-filter: blur(10px);\n    overflow: hidden;\n    width: 340px;\n    height: 480px;\n}\n\n\n.ril__image{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
