

export const tableHeaders = [
    { labelKey: 'Promocode Id', value: 'promocodeId' },
    { labelKey: 'Promocode', value: 'promocode' },
    { labelKey: 'Name', value: 'name' },
    { labelKey: 'Type', value: 'type' },
    { labelKey: 'Discount/Bonus Percentage', value: 'discountPercentage' },
    { labelKey: 'Per User Limit', value: 'perUserLimit' },
    { labelKey: 'Max Users Availed', value: 'maxUsersAvailed' },
    { labelKey: 'Used Count', value: 'maxUsersAvailedCount' },
    { labelKey: 'Is Active', value: 'isActive' },
    { labelKey: 'Action', value: 'Action' },
];


export const viewPromocodeHeaders = [
    { labelKey: 'Promocode Id', value: 'promoCodeId' },
    { labelKey: 'Promo Code', value: 'promoCode' },
    { labelKey: 'Used Count', value: 'usedCount' },
    { labelKey: 'Status', value: 'isActive' },
    { labelKey: 'Start Date', value: 'startDate' },
    { labelKey: 'End Date', value: 'endDate' },
    { labelKey: 'Deleted Date', value: 'deletedAt' },
    { labelKey: 'Action', value: 'Action' },

  ];
  
  export const CRM_PROMOTION_TYPE = [
    {label: 'All', value:''},
    {label: 'Scheduled', value: 'scheduled-campaign'},
    {label: 'Triggered', value: 'triggered-campaign'}
]