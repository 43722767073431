import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { AdminRoutes } from '../../routes'
import NotFound from '../NotFound'
import AdminSignIn from '../AdminSignin'
import PrivateRoute from '../../components/PrivateRoute'
import EditPackageDetails from '../Packages/components/EditPackageDetails'
import CreatePackages from '../Packages/components/CreatePackages'
import Packages from '../Packages'
import PlayerDetails from '../PlayerDetails'
import Players from '../Players'
import Dashboard from '../Dashboard'
import CMSListing from '../CMS'
import CMSDetail from '../CMS/components/CmsDetail'
import EditCms from '../CMS/components/EditCms'
import CreateCms from '../CMS/components/CreateCms'
import ImageGallery from '../ImageGallery'
import Staff from '../Staff'
import Countries from '../Countries'
import CreateStaffAdmin from '../Staff/components/CreateStaffAdmin'
import EditStaffAdmin from '../Staff/components/EditStaffAdmin'
import AdminDetails from '../AdminDetails'
import EmailTemplate from '../EmailTemplate'
import EditEmailTemplate from '../EmailTemplate/editEmailTemplate'
import ProfilePage from '../ProfilePage'
import CasinoProviders from '../CasinoProviders'
import CasinoAggregator from '../CasinoManagement/Aggregators/index'
import BannerManagement from '../BannerManagement'
import CasinoCategory from '../CasinoCategory'
import ReorderCategory from '../CasinoCategory/components/ReorderCategory'
import CasinoSubCategory from '../CasinoSubCategory'
import CasinoGames from '../CasinoGames'
import ReorderSubCategory from '../CasinoSubCategory/components/ReorderSubCategory'
import AddSubCategoryGames from '../AddSubCategoryGames'
import RestrictProviderCountries from '../RestrictProviderCountries'
import GameReorder from '../GameReorder'
import RestrictedProviders from '../RestrictedProviders'
import RestrictedGames from '../RestrictedGames'
import CreateEmailTemplate from '../EmailTemplate/createEmailTemplate'
import EditManualTemplate from '../EmailTemplate/EditManualTemplate'
import BonusListing from '../Bonus'
import CreateBonus from '../Bonus/components/CreateBonus'
import EditBonus from '../Bonus/components/EditBonus'
import BonusDetail from '../Bonus/components/BonusDetail'
import CasinoTransaction from '../CasinoTransaction'
import BankingTransaction from '../BankingTransaction'
import WithdrawRequests from '../WithdrawRequest'
import ReorderPackages from '../Packages/components/ReorderPackages'
import ReorderFtpBonus from '../Packages/components/ReorderFtpBonusPage'
import ContentPagesListing from '../ContentPages'
import ContentPageDetails from '../ContentPages/components/PageDetails/ContentPageDetails'
import AdminAffiliate from '../AdminAffiliate'
import CreateAffiliate from '../AdminAffiliate/components/CreateAffiliate'
import EditAffiliate from '../AdminAffiliate/components/EditAffiliate'
import Tournaments from '../Tournaments'
import CreateTournament from '../Tournaments/components/CreateTournament'
import EditTournament from '../Tournaments/components/EditTournament'
import ViewTournament from '../Tournaments/components/ViewTournament'
import ViewTier from '../Tier/components/ViewTier'
import EditTier from '../Tier/components/EditTier'
import CreateTier from '../Tier/components/CreateTier'
import Tiers from '../Tier'
import Raffle from '../Raffle'
import CreateRaffle from '../Raffle/component/CreateRaffle'
import EditRaffle from '../Raffle/component/EditRaffle'
import RafflePayout from '../Raffle/component/RafflePayout'
import ViewRaffle from '../Raffle/component/ViewRaffle'
import PromotionBonus from '../PromotionBonus'
import CreatePromotionBonus from '../PromotionBonus/component/CreatePromotionBonus'
import EditPromotionBonus from '../PromotionBonus/component/EditPromotionBonus'
import ViewPromotionBonus from '../PromotionBonus/component/ViewPromotionBonus'
import SpinWheel from '../SpinWheel'
import EditSpinWheel from '../SpinWheel/EditSpinWheel'
import PromoCodeBonus from '../Promocode'
import CreatePromoCode from '../Promocode/component/CreatePromoCode'
import EditPromoCode from '../Promocode/component/EditPromoCode'
import ViewPromoCode from '../Promocode/component/ViewPromoCode'
import ReferralBonusListing from '../ReferralBonus'
import CreateReferralBonus from '../ReferralBonus/components/CreateReferralBonus'
import EditReferralBonus from '../ReferralBonus/components/EditReferralBonus'
import ReferralBonusDetail from '../ReferralBonus/components/ReferralBonusDetail'
import RedeemRequestRule from '../WithdrawRequest/Components/RedeemRequestRule'
import CreateRedeemRules from '../WithdrawRequest/Components/CreateRedeemRules'
import EditRedeemRules from '../WithdrawRequest/Components/EditRedeemRules'
import ReorderProvider from '../CasinoProviders/components/ReorderProvider'
import ViewPackages from '../Packages/components/ViewPackages'
import VaultData from '../VaultData'
import UnarchivePage from '../Packages/components/UnarchivePage'
import ExportCenter from '../ExportCenter'
import AmoeData from '../../components/Amoe/AmoeData'
import GeoBlocking from '../GeoBlocking'
import DomainBlocking from '../DomainBlocking'
import CreateBlockDomain from '../DomainBlocking/component/CreateBlockDomain'
import EditBlockedDomain from '../DomainBlocking/component/EditBlockedDomain'
import PromoCodeBlocking from '../PromoCodeBlocking'
import CRMPromoBonus from '../CRMPromotion'
import CreateCRMPromoBonus from '../CRMPromotion/component/CreatePromoCode'
import EditCRMPromoBonus from '../CRMPromotion/component/EditPromoCode'
import ViewCRMPromoBonus from '../CRMPromotion/component/ViewPromoCode'
import CreateCRMPromoCode from '../CRMPromoCode/component/CreatePromoCode'
import EditCRMPromoCode from '../CRMPromoCode/component/EditPromoCode'
import ViewCRMPromoCode from '../CRMPromoCode/component/ViewPromoCode'
import CRMPromoCode from '../CRMPromoCode'
import ViewMoreCRMPromoBonus from '../CRMPromotion/component/ViewMore'
import ViewArchivePackages from '../Packages/components/ViewArchivePackages'
import ReorderTournaments from '../Tournaments/components/ReorderTournaments'
import EmailCenter from '../EmailCenter'

import SendMail from '../EmailCenter/components/SendMail'
import  Temp from '../EmailCenter/components/CreateEmail'
import CreateEmail from '../EmailCenter/components/CreateEmail'
import EditTemplate from '../EmailCenter/components/EditTemplate'

const AdminPages = () => (
  <Routes>
    {/* Public Routes without sidebar */}
    <Route
      path={AdminRoutes.AdminSignin}
      element={<AdminSignIn />}
    />
    <Route
      path={AdminRoutes.DefaultRoute}
      element={<AdminSignIn />}
    />

    <Route
      path={AdminRoutes.AdminRoute}
      element={<AdminSignIn />}
    />

    <Route
      path={AdminRoutes.Dashboard}
      element={
        <PrivateRoute module={{ Report: 'DR' }}>
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.Profile}
      element={
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.Players}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Players />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.PlayerDetails}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <PlayerDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.Packages}
      element={
        <PrivateRoute module={{ Package: 'R' }}>
          <Packages />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.Tournament}
      element={
        <PrivateRoute module={{ Tournaments: 'R' }}>
          <Tournaments />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.Tier}
      element={
        <PrivateRoute module={{ Tiers: 'R' }}>
          <Tiers />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CreatePackage}
      element={
        <PrivateRoute module={{ Package: 'C' }}>
          <CreatePackages />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ReorderPackage}
      element={
        <PrivateRoute module={{ Package: 'U' }}>
          <ReorderPackages />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.UnarchivePackage}
      element={
        <PrivateRoute module={{ Package: 'U' }}>
          <UnarchivePage />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ReorderFtpBonus}
      element={
        <PrivateRoute module={{ Package: 'U' }}>
          <ReorderFtpBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.EditPackageDetails}
      element={
        <PrivateRoute module={{ Package: 'U' }}>
          <EditPackageDetails />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.RaffleEdit}
      element={
        <PrivateRoute module={{ Raffles: 'U' }}>
          <EditRaffle />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CasinoProviders}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoProviders />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.Aggregators}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoAggregator />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.RestrictedProviderCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <RestrictProviderCountries />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CasinoCategories}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoCategory />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CasinoGames}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoGames />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ReorderCasinoCategories}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <ReorderCategory />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ReorderCasinoProviders}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <ReorderProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CasinoSubCategories}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoSubCategory />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ReorderCasinoSubCategories}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <ReorderSubCategory />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.AddSubCategoryGames}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <AddSubCategoryGames />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ReorderGames}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <GameReorder />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.BannerManagement}
      element={
        <PrivateRoute module={{ Banner: 'R' }}>
          <BannerManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CmsListing}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <CMSListing />
        </PrivateRoute>
      }
    />
    {/* <Route
      path={AdminRoutes.ContentPagesListing}
      element={
        <PrivateRoute>
          <ContentPagesListing />
        </PrivateRoute>
      }
    /> */}
    <Route
      path={AdminRoutes.ContentPageDetails}
      element={
        <PrivateRoute>
          <ContentPageDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CmsDetails}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <CMSDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CmsEdit}
      element={
        <PrivateRoute module={{ CMS: 'U' }}>
          <EditCms />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CmsCreate}
      element={
        <PrivateRoute module={{ CMS: 'C' }}>
          <CreateCms />
        </PrivateRoute>
      }
    />

<Route
      path={AdminRoutes.EmailCenter}
      element={
        <PrivateRoute module={{ emailCenter: 'R' }}>
          <EmailCenter/>
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.BonusListing}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusListing />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.BonusCreate}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CreateBonus />
        </PrivateRoute>
      }
    />
     <Route
      path={AdminRoutes.EmailCreate}
      element={
        <PrivateRoute module={{ emailCenter: 'C' }}>
<CreateEmail/>
        </PrivateRoute>
      }
    />
     <Route
      path={AdminRoutes.EmailSend}
      element={
        <PrivateRoute module={{ emailCenter: 'C' }}>
          <SendMail/>
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.EmailEdit}
      element={
        <PrivateRoute module={{emailCenter: 'C' }}>
          <EditTemplate/>
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.BonusEdit}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <EditBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.BonusDetails}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.ReferralBonusListing}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <ReferralBonusListing />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.ReferralBonusCreate}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CreateReferralBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.ReferralBonusEdit}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <EditReferralBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.ReferralBonusDetails}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <ReferralBonusDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.SpinWheel}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <SpinWheel />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.SpinWheelEdit}
      element={
        <PrivateRoute module={{ Admins: 'U' }}>
          <EditSpinWheel />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.ImageGallery}
      element={
        <PrivateRoute>
          <ImageGallery module={{ ImageGallery: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.Staff}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Staff />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.Countries}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Countries />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CountriesRestrictedProviders}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <RestrictedProviders />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CountriesRestrictedGames}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <RestrictedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CreateAdmin}
      element={
        <PrivateRoute module={{ Admins: 'C' }}>
          <CreateStaffAdmin />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.EditAdmin}
      element={
        <PrivateRoute module={{ Admins: 'U' }}>
          <EditStaffAdmin />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.AdminDetails}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <AdminDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.EmailTemplates}
      element={
        <PrivateRoute module={{ EmailTemplate: 'R' }}>
          <EmailTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CreateEmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'C' }}>
          <CreateEmailTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.EditManualTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'U' }}>
          <EditManualTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.EditEmailTemplates}
      element={
        <PrivateRoute module={{ EmailTemplate: 'U' }}>
          {/* <EditEmailTemplate /> */}
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CasinoTransactions}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <CasinoTransaction />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.BankingTransactions}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <BankingTransaction />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.Raffle}
      element={
        <PrivateRoute module={{ Raffles: 'R' }}>
          <Raffle />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.RafflePayout}
      element={
        <PrivateRoute module={{ RafflePayout: 'R' }}>
          <RafflePayout />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.RaffleView}
      element={
        <PrivateRoute module={{ Raffles: 'R' }}>
          <ViewRaffle />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.Affiliate}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }}>
          <AdminAffiliate />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CreateAffiliate}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }}>
          <CreateAffiliate />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.AffiliateDetail}
      element={
        <PrivateRoute module={{ Affiliates: 'R' }}>
          <EditAffiliate />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.WithdrawRequest}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <WithdrawRequests />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.VaultRequest}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <VaultData />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.AmoeRequest}
      element={
        <PrivateRoute module={{ PostalCode: 'R' }}>
          <AmoeData />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.RedeemReqRuleConfig}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <RedeemRequestRule />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CreateRedeemReqRuleConfig}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <CreateRedeemRules />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.EditRedeemReqRuleConfig}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <EditRedeemRules />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.TournamentEdit}
      element={
        <PrivateRoute module={{ Tournaments: 'R' }}>
          <EditTournament />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.tournamentCreate}
      element={
        <PrivateRoute module={{ Tournaments: 'R' }}>
          <CreateTournament type={"CREATE"}/>
        </PrivateRoute>
      }
    />
     <Route
      path={AdminRoutes.ReordertournamentList}
      element={
        <PrivateRoute module={{ Tournaments: 'U' }}>
          <ReorderTournaments />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.tierDetails}
      element={
        <PrivateRoute module={{ Tiers: 'R' }}>
          <ViewTier />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.tierEdit}
      element={
        <PrivateRoute module={{ Tiers: 'U' }}>
          <EditTier />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.tierCreate}
      element={
        <PrivateRoute module={{ Tiers: 'C' }}>
          <CreateTier />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.RaffleCreate}
      element={
        <PrivateRoute module={{ Raffles: 'C' }}>
          <CreateRaffle />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.PromotionBonus}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <PromotionBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.PromotionBonusCreate}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <CreatePromotionBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.PromotionBonusEdit}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <EditPromotionBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.PromotionBonusView}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <ViewPromotionBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.PromoCodeBonus}
      element={
        <PrivateRoute module={{ Promocode: 'R' }}>
          <PromoCodeBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.PromoCodeCreate}
      element={
        <PrivateRoute module={{ Promocode: 'R' }}>
          <CreatePromoCode />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.GeoBlocking}
      element={
        <PrivateRoute module={{ GeoComply: "R" }}>
          <GeoBlocking />
        </PrivateRoute>
      }
    /> 
      <Route
      path={AdminRoutes.DomainBlock}
      element={
        <PrivateRoute module={{ BlockUsers: "R" }}>
          <DomainBlocking />
        </PrivateRoute>
      }
    />

<Route
      path={AdminRoutes.DomainBlockCreate}
      element={
        <PrivateRoute module={{ BlockUsers: "R" }}>
          <CreateBlockDomain />
        </PrivateRoute>
      }
    />

<Route
      path={AdminRoutes.DomainBlockEdit}
      element={
        <PrivateRoute module={{ BlockUsers: "R" }}>
          <EditBlockedDomain />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.PromoCodeEdit}
      element={
        <PrivateRoute module={{ Promocode: 'R' }}>
          <EditPromoCode />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.PromoCodeView}
      element={
        <PrivateRoute module={{ Promocode: 'R' }}>
          <ViewPromoCode />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.CRMPromoBonus}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <CRMPromoBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoBonusCreate}
      element={
        <PrivateRoute module={{ PromotionBonus: 'C' }}>
          <CreateCRMPromoBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoBonusEdit}
      element={
        <PrivateRoute module={{ PromotionBonus: 'U' }}>
          <EditCRMPromoBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoBonusView}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <ViewCRMPromoBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoBonusViewMore}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <ViewMoreCRMPromoBonus />
        </PrivateRoute>
      }
    />

   <Route
      path={AdminRoutes.CRMPromoCode}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <CRMPromoCode />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoCodeCreate}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <CreateCRMPromoCode />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoCodeEdit}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <EditCRMPromoCode />
        </PrivateRoute>
      }
    />
    <Route
      path={AdminRoutes.CrmPromoCodeView}
      element={
        <PrivateRoute module={{ PromotionBonus: 'R' }}>
          <ViewCRMPromoCode />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.PromoCodeBlocking}
      element={
        <PrivateRoute module={{ BlockUsers: 'R'  }}>
          <PromoCodeBlocking />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.ViewPackages}
      element={
        <PrivateRoute module={{ Package: 'R' }}>
          <ViewPackages />
        </PrivateRoute>
      }
    />

    <Route
      path={AdminRoutes.ViewArchivePackages}
      element={
        <PrivateRoute module={{ Package: 'R' }}>
          <ViewArchivePackages />
        </PrivateRoute>
      }
    />
    
    <Route
      path={AdminRoutes.TournamentDetails}
      element={
        <PrivateRoute module={{ Tournaments: 'R' }}>
          <ViewTournament />
        </PrivateRoute>
      }
    />

   
     <Route
      path={AdminRoutes.ExportCenter}
      element={
        <PrivateRoute module={{ ExportCenter: 'R' }}>
          <ExportCenter/>
         </PrivateRoute>
      }
    />

    <Route path={AdminRoutes.NotFound} element={<NotFound />} />
    <Route
      path='*'
      element={<Navigate replace to={AdminRoutes.NotFound} />}
    />
  </Routes>
)
export default AdminPages