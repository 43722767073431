import React from "react";
import { Button, Row, Col, Table, Form } from "@themesberg/react-bootstrap";
import { CRM_PROMOTION_TYPE, tableHeaders } from "./constant";
import Trigger from "../../components/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faCheckSquare,
  faEdit,
  faEye,
  faWindowClose,
} from "@fortawesome/free-regular-svg-icons";
import { AdminRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/Pagination";
import { InlineLoader } from "../../components/Preloader";
import {
  ConfirmationModal,
  DeleteConfirmationModal,
} from "../../components/ConfirmationModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { searchRegEx } from "../../utils/helper";
import useCheckPermission from "../../utils/checkPermission";
import usePromoCodeListing from "./hooks/usePromoCodeListing";

const CRMPromoCode = () => {
  const navigate = useNavigate();
  const {
    promoCodeList,
    selected,
    loading,
    page,
    totalPages,
    setPage,
    limit,
    setLimit,
    handleShow,
    show,
    setShow,
    handleYes,
    active,
    handleDelete,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    setSearch,
    search,
    setOrderBy,
    sort,
    over,
    setOver,
    setSort,
    deleteLoading,
    updateloading,
    selectedType,
    setSelectedType,
    promoName,
    setpromoName,
  } = usePromoCodeListing();
  const { isHidden } = useCheckPermission();

  return (
    <>
      <Row className="mb-2">
        <Col>
          <h3>CRM Purchase Promo Codes</h3>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              hidden={isHidden({ module: { key: "Promocode", value: "C" } })}
              size="sm"
              style={{ marginRight: "10px" }}
              onClick={() => {
                navigate(AdminRoutes.CrmPromoCodeCreate);
              }}
            >
              Create
            </Button>
          </div>
        </Col>
        <Row className="mb-2">
          <Col>
            <Form.Label
              style={{
                marginBottom: "0",
                marginRight: "15px",
                marginTop: "8px",
              }}
            >
              Search
            </Form.Label>
            <Form.Control
              type="search"
              placeholder="Search Promocode"
              value={search}
              style={{
                maxWidth: "330px",
                marginRight: "10px",
                marginTop: "5px",
              }}
              onChange={(event) => {
                setPage(1);
                const mySearch = event.target.value.replace(searchRegEx, "");
                setSearch(mySearch);
              }}
            />
          </Col>
          <Col>
            <Form.Label
              style={{
                marginBottom: "0",
                marginRight: "15px",
                marginTop: "8px",
              }}
            >
              Promotion Name
            </Form.Label>
            <Form.Control
              type="search"
              placeholder="Search by Name"
              value={promoName}
              style={{
                maxWidth: "330px",
                marginRight: "10px",
                marginTop: "5px",
              }}
              onChange={(event) => {
                setPage(1);
                const mySearch = event.target.value.replace(searchRegEx, "");
                setpromoName(mySearch);
              }}
            />
          </Col>
          <Col>
            <Form.Label column="sm" className="mx-auto text-nowrap px-2">
              Promotion Type
            </Form.Label>

            <Form.Select
              style={{
                maxWidth: "330px",
                marginRight: "10px",
                marginTop: "5px",
              }}
              onChange={(e) => {
                setPage(1);
                setSelectedType(e.target.value);
              }}
              value={selectedType}
            >
              {/* <option value=''>{t('transactions.filters.actionTypeOpt')}</option> */}
              {CRM_PROMOTION_TYPE &&
                CRM_PROMOTION_TYPE?.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Row>
      </Row>
      <Table
        bordered
        striped
        responsive
        hover
        size="sm"
        className="text-center mt-4"
      >
        <thead className="thead-dark">
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
                onClick={() => h.value !== "" && setOrderBy(h.value)}
                style={{
                  cursor: "pointer",
                }}
                className={selected(h) ? "border-3 border border-blue" : ""}
              >
                {h.labelKey}{" "}
                {selected(h) &&
                  (sort === "ASC" ? (
                    <FontAwesomeIcon
                      style={over ? { color: "red" } : {}}
                      icon={faArrowAltCircleUp}
                      onClick={() => setSort("DESC")}
                      onMouseOver={() => setOver(true)}
                      onMouseLeave={() => setOver(false)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={over ? { color: "red" } : {}}
                      icon={faArrowAltCircleDown}
                      onClick={() => setSort("ASC")}
                      onMouseOver={() => setOver(true)}
                      onMouseLeave={() => setOver(false)}
                    />
                  ))}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={10} className="text-center">
                <InlineLoader />
              </td>
            </tr>
          ) : promoCodeList?.promocodeDetail?.count > 0 ? (
            promoCodeList.promocodeDetail.rows.map(
              ({
                promocodeId,
                promocode,
                discountPercentage,
                perUserLimit,
                maxUsersAvailed,
                isActive,
                validTill,
                crmPromotion,
                maxUsersAvailedCount,
                isDiscountOnAmount
              }) => (
                <tr key={promocodeId}>
                  <td>{promocodeId}</td>
                  <td>{promocode}</td>
                  <td>{crmPromotion.name}</td>
                  <td>{crmPromotion.promotionType}</td>
                  <td>{discountPercentage} {isDiscountOnAmount? "% Discount": "% Bonus"}</td>
                  <td>{perUserLimit}</td>
                  <td>{maxUsersAvailed === null ? "-" : maxUsersAvailed}</td>
                  <td>{maxUsersAvailedCount}</td>
                  <td>{isActive ? "Active" : "Inactive"}</td>

                  <td>
                    <Trigger message={"View"} id={promocodeId + "view"} />
                    <Button
                      id={promocodeId + "view"}
                      className="m-1"
                      size="sm"
                      variant="info"
                      onClick={() =>
                        navigate(
                          `${AdminRoutes.CrmPromoCodeView.split(
                            ":"
                          ).shift()}${promocode}`
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                    <Trigger message="Edit" id={promocodeId + "edit"} />
                    <Button
                      id={promocodeId + "edit"}
                      hidden={isHidden({
                        module: { key: "Promocode", value: "U" },
                      })}
                      className="m-1"
                      size="sm"
                      variant="warning"
                      onClick={() =>
                        navigate(
                          `${AdminRoutes.CrmPromoCodeEdit.split(
                            ":"
                          ).shift()}${promocodeId}`
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Trigger message="Delete" id={promocodeId + "delete"} />
                    <Button
                      id={promocodeId + "delete"}
                      hidden={isHidden({
                        module: { key: "Promocode", value: "U" },
                      })}
                      className="m-1"
                      size="sm"
                      variant="warning"
                      onClick={() => handleDelete(promocode)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    {!isActive ? (
                      <>
                        <Trigger
                          message="Set Status Active"
                          id={promocodeId + "active"}
                        />
                        <Button
                          id={promocodeId + "active"}
                          hidden={isHidden({
                            module: { key: "Promocode", value: "U" },
                          })}
                          className="m-1"
                          size="sm"
                          variant="success"
                          onClick={() => handleShow(promocodeId, isActive)}
                        >
                          <FontAwesomeIcon icon={faCheckSquare} />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Trigger
                          message="Set Status In-Active"
                          id={promocodeId + "inactive"}
                        />
                        <Button
                          id={promocodeId + "inactive"}
                          hidden={isHidden({
                            module: { key: "Promocode", value: "U" },
                          })}
                          className="m-1"
                          size="sm"
                          variant="danger"
                          onClick={() => handleShow(promocodeId, isActive)}
                        >
                          <FontAwesomeIcon icon={faWindowClose} />
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              )
            )
          ) : (
            <tr>
              <td colSpan={10} className="text-danger text-center">
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {promoCodeList?.promocodeDetail?.count !== 0 && (
        <PaginationComponent
          page={promoCodeList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          loading={updateloading}
        />
      )}
      {deleteModalShow && (
        <DeleteConfirmationModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          handleDeleteYes={handleDeleteYes}
          loading={deleteLoading}
        />
      )}
    </>
  );
};

export default CRMPromoCode;
