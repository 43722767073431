import { useEffect, useState } from 'react'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { getAllTransactions } from '../../../utils/apiCalls'
import { useDebounce } from 'use-debounce'
import useTimeConversion from '../../../utils/TimeConversion'
import { timeZones } from '../../Dashboard/constants'
import { convertTimeZone, convertToUtc, getFormattedTimeZoneOffset } from '../../../utils/helper'
import { useGetGamesIdsQuery } from '../../../reactQuery/hooks/customQueryHook'

const useCasinoTransactionsList = (email) => {
  const { userId } = useParams()
  const timezone = getItem("timezone")
  const { t } = useTranslation('players')
  const [selectedCurrency, setSelectedCurrency] = useState('all')
  const [selectedAction, setSelectedAction] = useState('all')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('all')
  const [gameId, setGameId] = useState('')
  const [gameName, setGameName]= useState('')
  const [search, setSearch] = useState('')
  const [searchCasinoId, setSearchCasinoId] = useState('');
  const [scCoin, setScCoin] = useState('');
  const [searchTransactionId, setSearchTransactionId] = useState('');
  const [debouncedSearch] = useDebounce(search, 500)
  const [debouncedSearchCasinoId] = useDebounce(searchCasinoId,500);
  const [debouncedSearchTransactionId] = useDebounce(searchTransactionId, 500);
  const [debounceSearchSccoin] = useDebounce(scCoin, 500);
  const [debounceSearchGameId] = useDebounce(gameId, 500);
  const timeZone = getItem("timezone");
  const timezoneOffset =  timeZone != null ? timeZones.find(x=> x.code === timeZone).value : getFormattedTimeZoneOffset()
  const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x=> x.value === timezoneOffset)?.code);
  const [startDate, setStartDate] = useState(convertTimeZone(new Date(), timeZoneCode));
  const [endDate, setEndDate] = useState(convertTimeZone(new Date(), timeZoneCode));
  const [gameIdsOptions, setGamesIdsOptions] = useState([])
  const [enabled, setEnabled] = useState(false)
  const [isSelectLoading, setIsSetLoading] = useState(false)
 
  useEffect(() => {
    if (gameId !== undefined) {
      setGameName('')
    }
  }, [gameId])
  useEffect(() => {
    if (gameName !== undefined) {
      setGameId('')
    }
  }, [gameName])
  useEffect(()=>{
    setTimeZoneCode(timeZones.find(x=> x.value === timezoneOffset)?.code)
  },[timezoneOffset])
  
  useEffect(() => {
    setStartDate(convertTimeZone(new Date(), timeZoneCode));
    setEndDate(convertTimeZone(new Date(), timeZoneCode));
  }, [timeZoneCode]);
  
  const successToggler = (data) => {
    if (data?.length) {
      const tempData = []
      data?.map((item) => {
        tempData.push({
          label: item.name,
          value: item.masterCasinoGameId
        })
      })
      setGamesIdsOptions(tempData)
    }
  }
  const { refetch: fetchData, isLoading } = useGetGamesIdsQuery({params:{},
    enabled,
    successToggler
  })
  useEffect(() => {
    fetchData()
  }, [])

  const { data: transactionData, isLoading: loading } = useQuery({
    queryKey: ['transactionList', limit, page, userId, selectedCurrency, status, selectedAction, 
      convertToUtc(startDate), convertToUtc(endDate), 
    debouncedSearch,Number(debouncedSearchCasinoId), debouncedSearchTransactionId, timezone, debounceSearchGameId, debounceSearchSccoin, gameName],
    queryFn: ({ queryKey }) => {
      const params = {pageNo: queryKey[2], limit: queryKey[1]};
      if (queryKey[3]) params.userId = queryKey[3]
      if (queryKey[4]) params.amountType = queryKey[4]
      if (queryKey[5]) params.status = queryKey[5]
      if (queryKey[6]) params.transactionType = queryKey[6]
      if (queryKey[7]) params.startDate = queryKey[7]
      if (queryKey[8]) params.endDate = queryKey[8]
      if (queryKey[9]) params.email = queryKey[9]
      if (queryKey[10]) params.casinoTransactionId = queryKey[10]
      if (queryKey[11]) params.externalTransactionId = queryKey[11]
      if (queryKey[12]) params.timezone = queryKey[12]
      if (queryKey[13] || queryKey[15]) params.gameId = queryKey[13] || queryKey[15]?.value
      if (queryKey[14]) params.sweepCoinUsed = queryKey[14]
      return getAllTransactions(params)
    },
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.transactionDetail
  })

  const totalPages = Math.ceil(transactionData?.count / limit)

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/v1/payment/casino-transactions?csvDownload=true&limit=${limit}&pageNo=${page}&startDate=${convertToUtc(startDate)}&endDate=${convertToUtc(endDate)}&transactionType=${selectedAction}&status=${status}&timezone=${timezone}&email=${search}&amountType=${selectedCurrency}`
  
  return {
    setSelectedCurrency,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    startDate, setStartDate,endDate, setEndDate,
    t,
    transactionData,
    loading,
    status,
    setStatus,
    gameId, setGameId,
    getCsvDownloadUrl,
    search, 
    setSearch,
    setSearchCasinoId,
    setSearchTransactionId,
    searchCasinoId,
    searchTransactionId,
    scCoin, setScCoin, gameName, setGameName,
    setGamesIdsOptions,
    gameIdsOptions,isSelectLoading
  }
}

export default useCasinoTransactionsList
