import { useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllTournamentsList } from '../../../utils/apiCalls'
import { toast } from '../../../components/Toast'
import { errorHandler, useDeleteTournament, useUpdateStatusMutation, useUpdateStatusTournamentMutation } from '../../../reactQuery/hooks/customMutationHook'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import useTimeConversion from '../../../utils/TimeConversion'
import { formatDateYMD, formattedDate, getDateDaysAgo, getDateThreeMonthsBefore } from '../../../utils/dateFormatter'
import { timeZones } from '../../Dashboard/constants.js'
import { getItem } from '../../../utils/storageUtils'
import { appendCurrentTime, convertTimeZone, convertToTimeZone, convertToUtc, getFormattedTimeZoneOffset } from '../../../utils/helper'
const useTournamentListing = ({isUTC}) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation(['tournaments'])
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  // const [orderBy, setOrderBy] = useState('tournamentId')
  const [sort, setSort] = useState('DESC')
  const [over, setOver] = useState(false)
  const [categoryId, setCategoryId] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [tournamentId, setTournamentId] = useState('')
  const [status, setStatus] = useState('all')
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const timeZone = getItem("timezone");
  const timezoneOffset = timeZone != null ? timeZones.find(x => x.code === timeZone).value : getFormattedTimeZoneOffset()
  const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x => x.value === timezoneOffset)?.code);

  const [startDate, setStartDate] = useState(convertTimeZone(getDateDaysAgo(10), timeZoneCode));
  const [endDate, setEndDate] = useState(convertTimeZone(new Date(), timeZoneCode));

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // In your useQuery
  const { data: tournamentList, isLoading: loading, refetch: tournamentRefetch } = useQuery({
    queryKey: ['tournamentList', limit, page, debouncedSearch, sort,
      status, selectedCurrency, convertToUtc(startDate), convertToUtc(endDate)],
    queryFn: ({ queryKey }) => {  
      const params = { pageNo: queryKey[2], limit: queryKey[1] };
      if (queryKey[3]) params.title = queryKey[3];
      // if (queryKey[4]) params.orderBy = queryKey[4];
      // if (queryKey[5]) params.sort = queryKey[5];
      if (queryKey[5]) params.status = queryKey[5];

      // Set up date handling

     let formattedStartDate = startDate;
      let formattedEndDate = endDate;

      if(isUTC){
        formattedStartDate = convertToUtc(appendCurrentTime(startDate));
        formattedEndDate = convertToUtc(appendCurrentTime(endDate));
      }

      // Adjust end date if start and end dates are the same
      if (formattedStartDate === formattedEndDate) {
        params.startDate = formatDate(formattedStartDate); // Format start date
        params.endDate = formatDate(new Date(formattedEndDate).setDate(new Date(formattedEndDate).getDate() + 1)); // Format end date
      } else {
        params.startDate = formatDate(formattedStartDate);
        params.endDate = formatDate(formattedEndDate);
      }

      return getAllTournamentsList(params);
    },
    select: (res) => res?.data?.data,
    refetchOnWindowFocus: false
  });

  const selected = (h) =>
    // orderBy === h.value &&
    h.labelKey !== 'Actions'

  const totalPages = Math.ceil(tournamentList?.count / limit)

  const { mutate: updateStatus,isLoading:updateloading } = useUpdateStatusTournamentMutation({
    onSuccess: ({ data }) => {
      // if (data.success) {
      if (data.message) toast(data.message, 'success')
      queryClient.invalidateQueries({ queryKey: ['tournamentList'] })
      // }
      setShow(false)
    }, onError: (error) => {
      errorHandler(error)
    }
  })

  const handleYes = () => {
    updateStatus({
      tournamentId: categoryId,
      isActive: active
    })
  }

  const handleClose = () => setShowModal(false)

  const handleShowModal = (type) => {
    setType(type)
    setShowModal(true)
  }

  const { mutate: deleteCategory } = useDeleteTournament({
    onSuccess: ({ data }) => {
      if (data?.success) {

        if (data?.message) toast(data.message, 'success')
        queryClient.invalidateQueries({ queryKey: ['tournamentList'] })
      }
      setDeleteModalShow(false)
    }
  })

  const handleDeleteYes = () => {
    deleteCategory({ tournamentId })
  }

  const handleDeleteModal = (id) => {
    setTournamentId(id)
    setDeleteModalShow(true)
  }






  useEffect(() => {
    setTimeZoneCode(timeZones.find(x => x.value === timezoneOffset)?.code)
  }, [timezoneOffset])

  useEffect(() => {
    setStartDate(convertTimeZone(getDateDaysAgo(10), timeZoneCode));
    setEndDate(convertTimeZone(new Date(), timeZoneCode));
  }, [timeZoneCode]);




  return {
    t,
    limit,
    startDate,
    setStartDate: (date) => setStartDate(moment(date).format('YYYY-MM-DD')),
    endDate,
    setEndDate: (date) => setEndDate(moment(date).format('YYYY-MM-DD')),
    page,
    loading,
    tournamentList,
    show,
    setLimit,
    setPage,
    setShow,
    totalPages,updateloading,
    handleYes,
    handleShowModal,
    showModal,
    type,
    tournamentRefetch,
    handleClose,
    selectedCategory,
    setSelectedCategory,
    active,
    navigate,
    handleDeleteModal,
    handleDeleteYes,
    deleteModalShow,
    setDeleteModalShow,
    // setOrderBy,
    selected,
    sort,
    setSort,
    over,
    setOver,
    status,
    setStatus,
    search,
    setSearch,
    selectedCurrency,
    setSelectedCurrency,
    state,
    setState, timezoneOffset
  }
}

export default useTournamentListing
