import React, { useState } from 'react'
import useCasinoTransactionsList from '../hooks/useCasinoTransactionsList'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import { formattedDate, getDateDaysAgo } from '../../../utils/dateFormatter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { actionTypeOptions, coinTypeOptions } from '../constants'
import CasinoTransactionsList from '../../../components/CasinoTransactionsList'
import { onDownloadCsvClick } from '../../../utils/helper'
import Datetime from 'react-datetime'
import ReactCreatable from '../../../components/ReactSelectField/ReactCreatable'

const CasinoTransactions = ({ email, isAllUser }) => {
  const {
    t,
    setSelectedCurrency,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    startDate, setStartDate, endDate, setEndDate,
    transactionData,
    loading,
    status,
    setStatus,
    getCsvDownloadUrl,
    search,
    setSearch,
    setSearchCasinoId,
    setSearchTransactionId,
    searchCasinoId,
    searchTransactionId, gameIdsOptions, isSelectLoading,
    gameId, setGameId, scCoin, setScCoin, gameName, setGameName
  } = useCasinoTransactionsList(email)
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = async () => {

    const formattedStartDate = formattedDate(startDate)
    const formattedEndDate = formattedDate(endDate)

    try {
      let currency = '';
      if (selectedCurrency == 0) {
        currency = 'GC';
      } else if (selectedCurrency == 1) {
        currency = 'SC';
      }
      let filename = 'Casino_Transaction';

      if (search) {
        filename += `_${search}`;
      }
      if (searchCasinoId) {
        filename += `_${searchCasinoId}`
      }
      if (searchTransactionId) {
        filename += `_${searchTransactionId}`
      }

      if (selectedCurrency == 'all' && selectedAction == 'all') {
        filename += `_${formattedStartDate}_${formattedEndDate}`;
      } else if (selectedCurrency == 'all') {
        filename += `_${selectedAction}_${formattedStartDate}_${formattedEndDate}`;
      } else if (selectedAction == 'all') {
        filename += `_${currency}_${formattedStartDate}_${formattedEndDate}`;
      } else {
        filename += `_${currency}_${selectedAction}_${formattedStartDate}_${formattedEndDate}`;
      }

      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvClick(url, filename)
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  return (
    <>
      {isAllUser && <Row className='mb-3'>
        <Col sm={12}>
          <h3>{t('transactions.casinoTransactions')}</h3>
        </Col>
      </Row>}
      <Row className='w-100 m-auto'>
        {isAllUser &&
          <Col xs='12' sm='6' lg='3' className='mb-3'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              {t('transactions.filters.search')}
            </Form.Label>

            <Form.Control
              type='search'
              value={search}
              placeholder='Search By Email'
              onChange={(event) => {
                setPage(1)
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )
              }
              }
            />
          </Col>}
        {isAllUser &&
          <Col xs='12' sm='6' lg='3' className='mb-3'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              {t('transactions.filters.casinoTransactionId')}
            </Form.Label>

            <Form.Control
              type='search'
              value={searchCasinoId}
              placeholder='Search By Casino Txn Id'
              onChange={(event) => {
                setPage(1)
                setSearchCasinoId(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )
              }
              }
            />
          </Col>}
        {isAllUser &&
          <Col xs='12' sm='6' lg='3' className='mb-3'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              {t('transactions.filters.externalTransactionId')}
            </Form.Label>

            <Form.Control
              type='search'
              value={searchTransactionId}
              placeholder='Search By External Txn Id'
              onChange={(event) => {
                setPage(1)
                setSearchTransactionId(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )
              }
              }
            />
          </Col>}

        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            {t('history.filters.coinType')}
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setPage(1)
              setSelectedCurrency(e.target.value)
            }
            }
            value={selectedCurrency}
          >
            {coinTypeOptions && coinTypeOptions?.map(
              ({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Action Type
          </Form.Label>

          <Form.Select
            onChange={(e) => {
              setPage(1)
              setSelectedAction(e.target.value)
            }}
            value={selectedAction}
          >
            {actionTypeOptions && actionTypeOptions?.map(
              ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
        </Col>
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Game Id
          </Form.Label>

          <Form.Control
            type='search'
            value={gameId}
            placeholder='Search By game Id'
            onChange={(event) => {
              setPage(1)
              setGameId(
                event.target.value.replace(/^0|[^\d]/g, '')
              )

            }}
          />
        </Col>
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Game Name
          </Form.Label>
          <ReactCreatable
            options={gameIdsOptions}
            value={gameName}
            setValue={(dataValue) => {
              setGameName(dataValue);
            }}
            isMulti={false}
            isLoading={isSelectLoading}
          />
        </Col>


        {/* <Col xs='12' sm='6' lg='3' className='mb-3'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Game Name
            </Form.Label>

            <Form.Control
              type='search'
              value={gameName}
              placeholder='Search By Game Name'
              onChange={(event) => {
                setPage(1)
                setGameName(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )
              }
              }
            />
          </Col> */}
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Sc Coin
          </Form.Label>

          <Form.Control
            type='search'
            value={scCoin}
            placeholder='Search By Sc Coin'
            onChange={(event) => {
              setPage(1)
              setScCoin(
                event.target.value
                  .replace(/[^0-9.]/g, '')
                  .replace(/(\..*?)\..*/g, '$1')
                  .replace(/^(\d+)(\.\d{0,2})?.*/, '$1$2')
              )
            }
            }
          />
        </Col>
        {/* <Col xs='12' sm='6' lg='4' className='mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          <DateRangePicker
            width="auto"
            state={state} setState={setState} timeZoneCode={timezoneOffset} startDate = {startDate} endDate={endDate}
          />
        </Col> */}
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Start Date
          </Form.Label>
          <Datetime
            value={startDate}
            onChange={(date) => setStartDate(date)}
            timeFormat={false}
          />
        </Col>
        <Col xs='12' sm='6' lg='3' className='mb-3'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            End Date
          </Form.Label>
          <Datetime
            value={endDate}
            timeFormat={false}
            onChange={(date) => setEndDate(date)}
          />
        </Col>

        <Col
          xs="12"
          sm="6"
          lg="1"
          className="d-flex align-items-end mt-2 mt-sm-0 mb-0 mb-sm-3"
        >
          <Trigger message="Reset Filters" id={"redo"} />
          <Button
            id={"redo"}
            variant="success"
            onClick={() => {
              setSearch("");
              setSelectedAction("all");
              setSelectedCurrency('all');
              setSearchCasinoId("");
              setSearchTransactionId("");
              setLimit(15);
              setPage(1);
              setStartDate(getDateDaysAgo(10)),
                setEndDate(new Date()),
                setGameId(''), setScCoin(''), setGameName('')
              setStatus("all");
            }}
          >
            <FontAwesomeIcon icon={faRedoAlt} />
          </Button>

          <Trigger message="Download as CSV" id={"csv"} />
          <Button
            id={"csv"}
            variant="success"
            style={{ marginLeft: "10px" }}
            onClick={handleDownloadClick}
            disabled={downloadInProgress || transactionData?.count === 0}
          >
            {downloadInProgress ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
              <FontAwesomeIcon icon={faFileDownload} />
            )}
          </Button>
        </Col>

      </Row>
      <CasinoTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        loading={loading}
        data={transactionData}
        isAllUser={isAllUser}
      />
    </>
  )
}

export default CasinoTransactions
