import {  useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getTournamentStatisticsData } from '../../../utils/apiCalls'
import {  appendCurrentTime, convertTimeZone, convertToUtc, getFormattedTimeZoneOffset, } from '../../../utils/helper'
import { timeZones } from '../../Dashboard/constants'
import { getItem, setItem } from '../../../utils/storageUtils'
import moment from 'moment'

const useTournamentStatisticsChart = ({initialStartDate, initialEndDate, type, isUTC}) => {
    const { tournamentId } = useParams()
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [selectedGame, setSelectedGame] = useState()
    const [selectedPlayer, setSelectedPlayer] = useState()

    const currentTimeZone = getItem("timezone");
    const currentTimezoneOffset = timeZones?.find(x => x.code === currentTimeZone)?.value;
    const timeZoneOffset = getFormattedTimeZoneOffset();
    const [timeStamp, setTimeStamp] = useState(currentTimezoneOffset ? currentTimezoneOffset : timeZoneOffset);
    const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x => x.value === timeStamp)?.code);

    const [isDateReady, setIsDateReady] = useState(false);

  useEffect(() => {
    setTimeZoneCode(timeZones.find(x => x.value === timeStamp)?.code);
    setItem("timezone", timeZones.find(x => x.value === timeStamp)?.code);
  }, [timeStamp]);

  useEffect(() => {
    const updatedStartDate = convertTimeZone(startDate, timeZoneCode);
    const updatedEndDate = convertTimeZone(new Date(), timeZoneCode);
    setStartDate(updatedStartDate);
    setEndDate(updatedEndDate);

    // Set flag once the dates are properly updated and ready for use in the query
    if (updatedStartDate && updatedEndDate) {
      setIsDateReady(true);
    }
  }, [timeZoneCode]);
    

    const { data: tournamentStatisticsData } = useQuery({
        queryKey: [`tournament-stats-${tournamentId}`, startDate, endDate, selectedGame, selectedPlayer],
        queryFn: ({ queryKey }) => {
            let formattedStartDate = startDate;
            let formattedEndDate = endDate;

             if(isUTC){
                    formattedStartDate = convertToUtc(appendCurrentTime(startDate));
                    formattedEndDate = convertToUtc(appendCurrentTime(endDate));
                  }

            const params = {};

            if (queryKey[1]) params.startDate = formattedStartDate ;
            if (queryKey[2]) params.endDate = formattedEndDate;
            if (queryKey[3]) params.gameId = queryKey[3]?.value;
            if (queryKey[4]) params.userId = queryKey[4]?.value;
            return getTournamentStatisticsData(params, { tournamentId })
        },
        select: (res) => res?.data?.data,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: isDateReady && !!startDate && !!endDate && type !== "CREATE" && type !== "EDIT"
    })

    return {
        tournamentStatisticsData,
        startDate,
    setStartDate: (date) => setStartDate(moment(date).format('YYYY-MM-DD')),
    endDate,
    setEndDate: (date) => setEndDate(moment(date).format('YYYY-MM-DD')),
        selectedGame,
        setSelectedGame,
        selectedPlayer,
        setSelectedPlayer,
        
    }
}

export default useTournamentStatisticsChart