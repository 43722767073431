import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Table, Form as BForm } from "@themesberg/react-bootstrap";
import {
  formatDateMDY,
} from "../../../utils/dateFormatter.js";
import { getCRMBonusUserDetailsapi} from "../../../utils/apiCalls.js";
import { useQuery } from "@tanstack/react-query";
import { viewUserDetailsHeaders } from "../constant.js";
import PaginationComponent from "../../../components/Pagination/index.jsx";


const ViewMoreCRMPromoBonus = ({ data }) => {
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('')
  const [sort, setSort] = useState('DESC')
  const  {crmPromotionId} = useParams();


  const { data: promoDetail, isLoading: loading } = useQuery({
    queryKey: ['promoDetail', limit, page, orderBy, sort, crmPromotionId],
    queryFn: ({ queryKey }) => {
      const params = { pageNo: queryKey[2], limit: queryKey[1], crmPromotionId: queryKey[5] };
      if (queryKey[3]) params.limit = queryKey[3]
      if (queryKey[3]) params.page = queryKey[3]
      if (queryKey[3]) params.orderBy = queryKey[3]
      if (queryKey[4]) params.sort = queryKey[4]
      if (queryKey[5]) params.crmPromotionId = queryKey[5]

      return getCRMBonusUserDetailsapi(params);
    },
    select: (res) => res?.data,
    refetchOnWindowFocus: false,
  });

  const totalPages = Math.ceil(promoDetail?.count / limit);


  
  return (
    <div>
      <Row>
        <Col sm={8}>
          <h3>View CRM Bonus User Details </h3>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>CRM Promotion Id</h6>
            <span >{promoDetail?.crmBonusDetails?.crmPromotionId}</span>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Promo code</h6>
            <span >{promoDetail?.crmBonusDetails?.promocode}</span>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Name</h6>
            <span >{promoDetail?.crmBonusDetails?.name}</span>
          </div>
        </Col>
        <Col xs={12} md={6} lg={3} >
          <div className='d-flex justify-content-between m-1 player-basic-info align-items-center'>
            <h6 className='mb-0 me-2'>Status</h6>
            <span >{promoDetail?.crmBonusDetails?.isActive == true ? "Active" : "Inactive"}</span>
          </div>
        </Col>

      </Row>

      <Row className="mt-3">
        <BForm.Label>User Details</BForm.Label>

        <Table
          bordered
          striped
          responsive
          hover
          size="sm"
          className="text-center mt-4"
        >
          <thead className="thead-dark">
            <tr>
              {viewUserDetailsHeaders.map((h, idx) => (
                <th
                  key={idx}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {h.labelKey}{" "}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {promoDetail?.userDetails?.count > 0 &&
              promoDetail?.userDetails?.rows?.map(
                ({ userId, User, claimedAt }) => {
                  return (
                    <tr key={userId}>
                      <td>{User?.userId}</td>
                      <td>{User?.username}</td>
                      <td><Link to={`/admin/player-details/${userId}`}>{User?.email}</Link></td>
                      <td>{User?.firstName} {User?.lastName}</td>
                      <td>{User?.lastName}</td>
                      <td>{User?.isActive ? "Active" : "Inactive"}</td>
                      <td>{claimedAt ? formatDateMDY(claimedAt) : 'Not Claimed'}</td>
                    </tr>
                  );
                }
              )}

            {promoDetail?.userDetails?.count === 0 && (
              <tr>
                <td colSpan={7} className="text-danger text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>


      {promoDetail?.userDetails?.count !== 0 && promoDetail?.userDetails?.rows?.length > 0 && (
        <PaginationComponent
          page={data?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

    </div>
  );
};

export default ViewMoreCRMPromoBonus;
