import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getTournamentDashboard } from '../../../utils/apiCalls'
import { appendCurrentTime, convertToUtc, } from '../../../utils/helper'
import {  useState } from 'react'
import moment from 'moment'


const useTournamentDashboardDetails = ({type}) => {
    const { tournamentId } = useParams()
      const [startDate, setStartDate] = useState();
      const [endDate, setEndDate] = useState();

    const [selectedGame, setSelectedGame] = useState()
    const [selectedPlayer, setSelectedPlayer] = useState()


    const { data: tournamentDashboardData } = useQuery({
        queryKey: [`tournamentDashboard-${tournamentId}`, startDate, endDate, selectedGame, selectedPlayer],
        queryFn: ({ queryKey }) => {
              const formattedStartDate = convertToUtc(appendCurrentTime(startDate));
                 const formattedEndDate = convertToUtc(appendCurrentTime(endDate));
            const params = {};
            if (queryKey[1]) params.startDate = formattedStartDate ;
            if (queryKey[2]) params.endDate = formattedEndDate;
            if (queryKey[3]) params.gameId = queryKey[3]?.value;
            if (queryKey[4]) params.userId = queryKey[4]?.value;
            return getTournamentDashboard(params, { tournamentId })
        },
        select: (res) => res?.data?.data,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: type !== "CREATE" && type !=="EDIT"
    })


    return {
        tournamentDashboardData,
        selectedGame,
        selectedPlayer,
        setSelectedGame,
        setSelectedPlayer,
        startDate,
        endDate,
        setStartDate: (date) => setStartDate(moment(date).format('MM-DD-YYYY')),
        setEndDate: (date) => setEndDate(moment(date).format('MM-DD-YYYY')),
        
    }
}

export default useTournamentDashboardDetails
