import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import {  getAllTournamentsListParams} from '../../../utils/apiCalls'
import { useEffect, useState } from 'react'
import { timeZones } from '../../Dashboard/constants'
import { getItem, setItem } from '../../../utils/storageUtils'
import { getFormattedTimeZoneOffset } from '../../../utils/helper'

const useTournamentDetails = () => {
  const { tournamentId } = useParams()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
   const currentTimeZone = getItem("timezone");
    const currentTimezoneOffset = timeZones?.find(x => x.code === currentTimeZone)?.value;
    const timeZoneOffset = getFormattedTimeZoneOffset();
    const [timeStamp, setTimeStamp] = useState(currentTimezoneOffset ? currentTimezoneOffset : timeZoneOffset);
  const [timeZoneCode, setTimeZoneCode] = useState(timeZones.find(x => x.value === timeStamp)?.code);

    useEffect(() => {
      setTimeZoneCode(timeZones.find(x => x.value === timeStamp)?.code);
      setItem("timezone", timeZones.find(x => x.value === timeStamp)?.code);
    }, [timeStamp]);

  const { data: tournamentData, isLoading: loading, refetch: refetchTournament } = useQuery({
    queryKey: ['tournamentId', limit, page, tournamentId ],
    queryFn: ({ queryKey }) => {
      const params = {pageNo: queryKey[2], limit: queryKey[1]}
      return getAllTournamentsListParams(params, {tournamentId})
    },
    select: (res) => res?.data?.data,
    refetchOnWindowFocus: false
  })

  const totalPages = Math.ceil(tournamentData?.tournamentLeaderBoard?.count / limit)

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/v1/tournament/${tournamentId}?csvDownload=true&timezone=${timeZoneCode}&limit=${limit}&pageNo=${page}`
  

  return {
    tournamentData,
    loading,
    navigate,
    refetchTournament,
    totalPages, limit, setLimit, 
    page, setPage,
    getCsvDownloadUrl
  }
}

export default useTournamentDetails
