export const tableHeaders = [
    { labelKey: 'headers.id', value: 'emailTemplateId' },
    { labelKey: 'headers.title', value: 'templateName' },
    
    { labelKey: 'headers.action', value: '' },
]

export const initialSet = {
    idSearch: null,
    emailSearch: '',
    firstNameSearch: '',
    lastNameSearch: '',
    userNameSearch: '',
    phoneSearch: '',
    tierSearch:'',
    affiliateIdSearch: '',
    regIpSearch: '',
    lastIpSearch: ''
   }