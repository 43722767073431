import React, { useEffect, useState } from "react";
import {
  Button,
  Form as BForm,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Table,
  Dropdown,
} from "@themesberg/react-bootstrap";
import PaginationComponent from "../../../components/Pagination";
import useCheckPermission from "../../../utils/checkPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Preloader, { InlineLoader } from "../../../components/Preloader";
import {
  faCheckSquare,
  faEdit,
  faTrash,
  faSave,
  faCancel,
  faEye,
  faArrowCircleUp,
  faArrowCircleDown,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Form, ErrorMessage } from "formik";
import Datetime from "react-datetime";
import { AdminRoutes } from "../../../routes";
import {
  addHours,
  formattedDate,
  getDateTime,
  getDateTimeByYMD,
} from "../../../utils/dateFormatter";
import Trigger from "../../../components/OverlayTrigger";
import ReactCreatable from "../../../components/ReactSelectField/ReactCreatable";
import { SketchPicker } from "react-color";
import { PACKAGE_USER_FILTER } from "../constants";
import { useGetPackageUserFilterQuery } from "../../../reactQuery/hooks/customQueryHook";
import {
  ConfirmationModal,
  DeleteConfirmationModal,
} from "../../../components/ConfirmationModal";

import useEditPackage from "../hooks/useEditPackage";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { orderBy } from "lodash";
import { onDownloadCsvClick, onDownloadCsvDirectClick } from "../../../utils/helper";
import { useDebounce } from "use-debounce";
import { toast } from "../../../components/Toast";

const PackageCreateForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  loading,
  navigate,
  setFieldValue,
  packageData,
  isEdit = false,
  typeOptions,
  typeValue,
  setTypeValue,
  isSelectLoading,
  handleCreateOption,
  selectedUserIds,
  setSelectedUserIds,
  handleDeleteModal,
  handleDeleteYes,
  deleteModalShow,
  setDeleteModalShow,
  handleYes,
  show,
  setShow,
  active,
  handleShow,
  isEditPage,
}) => {
  const { t } = useTranslation(["packages"]);

  const { isHidden } = useCheckPermission();
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedColorBackground, setSelectedColorBackground] = useState("");
  const yesterday = new Date(Date.now() - 86400000);
  const [debouncedValue] = useDebounce(values.filterValue, 600);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [ladderPackageList, setLadderPackageList] = useState([]);
  //  const [page, setPage] = useState(1);
  //  const [limit, setLimit] = useState(15);
  useEffect(() => {
    if (packageData && packageData?.textColor) {
      setSelectedColor(packageData?.textColor);
    }
    if (packageData && packageData?.backgroundColor) {
      setSelectedColorBackground(packageData?.backgroundColor);
    }
  }, [packageData]);

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    values.textColor = selectedColor;
  };

  const handleColorChangeBackground = (color) => {
    setSelectedColorBackground(color.hex);
    values.backgroundColor = selectedColorBackground;
  };

  const allFiltersSelected = Boolean(
    values.filterType &&
      values.filterOperator &&
      values.filterValue !== undefined &&
      values.filterValue !== null
  );

  const { data, isLoading: packageDataLoading } = useGetPackageUserFilterQuery({
    params: {
      // limit:limit,
      // pageNo:page,
      type: values.filterType,
      operator: values.filterOperator,
      // value: values.filterValue || packageData?.moreDetails?.filterValue,
      value:
        values.filterValue !== undefined
          ? debouncedValue
          : packageData?.moreDetails?.filterValue,
    },
    // enabled: values.filterType && values.filterOperator && (values.filterValue !== undefined),
    enabled: allFiltersSelected,
  });

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(allFiltersSelected && packageDataLoading);
  }, [allFiltersSelected, packageDataLoading]);

  //const totalPages = Math.ceil(data?.playerIds.length / limit);

  const getCsvDownloadUrl = () => {
    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/package/user-filter?csvDownload=true&type=${
      values.filterType
    }&operator=${encodeURIComponent(values.filterOperator)}&value=${
      values.filterValue
    }`;
    return url;
  };

  const handleDownloadClick = async () => {
    try {
      const filename = `User_Filter_${values.filterType}`;
      setDownloadInProgress(true);
      const url = getCsvDownloadUrl();
      await onDownloadCsvDirectClick(url, filename);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  useEffect(() => {
    if (values.amount) {
      const roundedScCoin = Math.ceil(values.amount);
      const roundedGcCoin = Math.ceil(values.amount) * 1000;

      setFieldValue("scCoin", roundedScCoin);
      setFieldValue("gcCoin", roundedGcCoin);
    } else {
      // Reset the values when amount is cleared
      setFieldValue("scCoin", "");
      setFieldValue("gcCoin", "");
    }
  }, [values.amount, setFieldValue]);

  useEffect(() => {
    if (values.gcCoin || values.scCoin) {
      setFieldValue("scCoin", values.scCoin);
      setFieldValue("gcCoin", values.gcCoin);
    } else {
      setFieldValue("scCoin", "");
      setFieldValue("gcCoin", "");
    }
  }, [values.gcCoin, setFieldValue]);
  useEffect(() => {
    if (values.bonusSc) {
      const roundedBonusGc = Math.ceil(values.bonusSc) * 1000;
      setFieldValue("bonusGc", roundedBonusGc);
    } else {
      // Reset the values when amount is cleared
      setFieldValue("bonusGc", 0);
    }
  }, [values.bonusSc, setFieldValue]);
  useEffect(() => {
    if (values.bonusGc || values.bonusSc) {
      setFieldValue("bonusSc", values.bonusSc);
      setFieldValue("bonusGc", values.bonusGc);
    } else {
      setFieldValue("bonusSc", 0);
      setFieldValue("bonusGc", 0);
    }
  }, [values.bonusGc, setFieldValue]);
  useEffect(() => {
    if (packageData) {
      setFieldValue("filterType", packageData?.moreDetails?.filterType || "");
      setFieldValue(
        "filterOperator",
        packageData?.moreDetails?.filterOperator || "="
      );
      setFieldValue("filterValue", packageData?.moreDetails?.filterValue || 0);
      setSelectedUserIds(packageData?.playerId || []);
    }
  }, [packageData, setFieldValue]);

  const handleSelectUser = (e, userId) => {
    const updatedSelectedUserIds = e.target.checked
      ? [...selectedUserIds, userId]
      : selectedUserIds.filter((id) => id !== userId);
    setSelectedUserIds(updatedSelectedUserIds);
  };

  const handleSelectAll = (e) => {
    const allUserIds = data?.playerIds?.map((user) => user.userId);
    setSelectedUserIds(e.target.checked ? allUserIds : []);
  };

  const handleFilterChange = (e) => {
    const newFilterType = e.target.value;
    if (
      packageData?.moreDetails?.filterType !== newFilterType &&
      String(packageData?.moreDetails?.filterValue) !== newFilterType &&
      packageData?.moreDetails?.filterOperator !== newFilterType
    ) {
      setSelectedUserIds([]);
    } else {
      setSelectedUserIds(packageData?.playerId);
    }
    handleChange(e);
  };

  const [selectedOption, setSelectedOption] = useState("Basic Package");

  useEffect(() => {
    if (values?.firstPurchaseApplicable) {
      setSelectedOption("Basic package");
    } else if (values?.welcomePurchaseBonusApplicable) {
      setSelectedOption("Welcome Purchase Packages");
    // }
    //  else if (values?.isSpecialPackage) {
    //   setSelectedOption("Special Purchase Package");
    } else if(values?.isLadderPackage){
      setSelectedOption("Ladder Packages")
    } 
    else {
      setSelectedOption("Basic Package");
    }
  }, [values]);

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
    handleEvent(eventKey);
  };
  const handleEvent = (option) => {
    switch (option) {
      case "Welcome Purchase Packages":
        setFieldValue("welcomePurchaseBonusApplicable", true);
        setFieldValue("isSpecialPackage", false);
        setFieldValue("firstPurchaseApplicable", false);
        setFieldValue("isValidFrom", false);
        setFieldValue("isValidUntil", false);
        setFieldValue("validTill", null);
        setFieldValue("validFrom", null);
        setFieldValue("ispurchaseNo",false)
        setFieldValue("purchaseNo",0)
        setFieldValue("isLadderPackage", false);

        break;
      // case "First Purchase Package":
      //   setFieldValue("firstPurchaseApplicable", true);
      //   setFieldValue("isSpecialPackage", false);
      //   setFieldValue("welcomePurchaseBonusApplicable", false);

      //   break;
      case "Special Purchase Package":
        setFieldValue("isSpecialPackage", true);
        setFieldValue("welcomePurchaseBonusApplicable", false);
        setFieldValue("firstPurchaseApplicable", false);
        break;
      case "Basic Package":
        setFieldValue("firstPurchaseApplicable", false); 
        setFieldValue("welcomePurchaseBonusApplicable", false);
        setFieldValue("isLadderPackage", false);

        break;
      case "Ladder Packages":
        setFieldValue("isLadderPackage", true);
        setFieldValue("firstPurchaseApplicable", false); 
        setFieldValue("welcomePurchaseBonusApplicable", false);

        break;
      default:
        break;
    }
  };

  const [showRows, setShowRows] = useState(false);
  const [formData, setFormData] = useState({});
  const [bonuses, setBonuses] = useState([]);
  const [bonusdata, setBonusData] = useState([]);

  useEffect(() => {
    setBonusData(values?.packageFirstPurchase);
  }, [values]);

  const handleButtonClick = () => {
    setShowRows(!showRows);
  };
  const handleCancel = () => {
    setShowRows(false);
    // setFormData({ sc: "", gc: "" });
    setLadderPackageList([]);
    setFieldValue('packageName','');
    setFieldValue('amount','');
    setFieldValue('scCoin','');
    setFieldValue('gcCoin','');
    setFieldValue('bonusSc','');
    setFieldValue('bonusGc', '');
    setFieldValue('isSpecialPackage',false)
  };
  const handleReset = () => {
    setBonuses([]);
  };
  const handleBonusAdd = () => {
    const updatedBonuses = [
      ...bonuses,
      {
        sc: formData.sc ? Number(formData.sc) : 0,
        gc: formData.gc ? Number(formData.gc) : 0,
      },
    ];
    const updatedBonusesJSON = JSON.stringify(updatedBonuses);
    setBonuses(updatedBonuses);
    values.ftpBonuses = updatedBonusesJSON;
    setFormData({ sc: "", gc: "" });
    //setShowRows(false);
  };

  const handleBonusChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [editRowId, setEditRowId] = useState(null);
  const [editValues, setEditValues] = useState({});
  const {
    updateBonus,
    createftpBonus,
    createFtpBonusloading,
    deleteftploading,
    statusFtploading,
    updataFtpbonusloading,
    editloading,
  } = useEditPackage();

  const handleFtpSubmit = (updateValues) => {
    updateBonus(updateValues);
    setEditRowId(null);
  };
  const handlecreateFtpSubmit = (updateValues) => {
    createftpBonus(updateValues);
    setFormData({ sc: "", gc: "" });

    setEditRowId(null);
  };
  const handleEditClick = (row) => {
    setEditRowId(row.packageFirstPurchaseId);
    setEditValues({
      firstPurchaseScBonus: row.firstPurchaseScBonus,
      firstPurchaseGcBonus: row.firstPurchaseGcBonus,
    });
  };
  const handleftpChange = (e, field) => {
    setEditValues({
      ...editValues,
      [field]: e.target.value,
    });
  };
  
  
  const handleAddHours = (setFieldValue, validFrom ,hoursToAdd )=>{
     validFrom = (validFrom ===null || validFrom === undefined) ? new Date() : new Date(validFrom);
     const updatedValidTill = addHours(validFrom, hoursToAdd);
     setFieldValue('validTill', updatedValidTill)
  }

  const handleAddLadderPackages = ()=>{
    const packageData = {
      packageName: values?.packageName,
      amount:values?.amount, 
      scCoin: values?.scCoin, 
      gcCoin: values?.gcCoin, 
      scBonus: values?.bonusSc, 
      gcBonus:values?.bonusGc,
      isSpecialPackage: values?.isSpecialPackage,
    }

    const alreadyExistsPackageName = ladderPackageList.find((pkg)=>pkg.packageName === packageData.packageName);
    if(alreadyExistsPackageName !==undefined){
      toast(`Package with same name already exists in the list`,'error');
      return;
    } 
    if(packageData.isSpecialPackage ===true){
        const specialLadderPackage = ladderPackageList.find((pkg)=>pkg.isSpecialPackage ===true);
        if(specialLadderPackage !==undefined){
          toast("Only one package can be a special package","error");
          return;
        } 
     }
     
     setLadderPackageList((prevList)=>{
      const updatedLadderPackageList = [...prevList, packageData]
      setFieldValue('ladderPackageData', updatedLadderPackageList);
      return updatedLadderPackageList;
    })
     
     setFieldValue('packageName','');
     setFieldValue('amount','');
     setFieldValue('scCoin','');
     setFieldValue('gcCoin','');
     setFieldValue('bonusSc','');
     setFieldValue('bonusGc', '');
     setFieldValue('isSpecialPackage',false)
  }
  
  const handleDeletePackage = (packageName)=>{
     const updatedLadderPackageList = ladderPackageList?.filter((pkg)=>pkg.packageName !==packageName);
     setLadderPackageList(updatedLadderPackageList);
     setFieldValue('ladderPackageData', updatedLadderPackageList);
  }

  const handleCheckBoxChange = (e)=>{
    setFieldValue('isValidFrom',e.target.checked);
    setFieldValue('validFrom',null)
  }
  
  return (
    <>
      <Form className="border rounded p-2">
        <div className="container-fluid">
          <Row>
            <div className="col-12 col-lg-6">
              <BForm.Label>Select Package Type</BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-package-type">
                    Select the type of package.
                  </Tooltip>
                }
              >
                <BForm.Select
                  name="packageType"
                  value={selectedOption}
                  onChange={(e) => handleSelect(e.target.value)}
                  onBlur={handleBlur}
                  //style={{ width: "60%" }}
                >
                  <option value="Basic Package">Basic Package</option>
                  <option value="Welcome Purchase Packages">
                    Welcome Purchase Package
                  </option>
                  {!isEditPage && <option value="Ladder Packages">
                    Ladder Packages
                  </option>}
                  {/*<option value="First Purchase Package">
                    First Purchase Package
                  </option> */}
                </BForm.Select>
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="packageType"
                className="text-danger"
              />
            </div>
           {(!values?.welcomePurchaseBonusApplicable && !values?.isLadderPackage) && <div className="col-12 col-lg-6">
              <BForm.Label>Package Purchase Number</BForm.Label>
              <div style={{position:"relative"}}>
              <BForm.Check
                type="switch"
                name="ispurchaseNo"
                checked={values.ispurchaseNo}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isEdit}
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "auto",
                  right: "10px",
                  zIndex: 2,
                }}
              />
              {values.ispurchaseNo === true ? (
                <>
                  <BForm.Control
                    type="number"
                    name="purchaseNo"
                    min="1"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-", "."].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    placeholder={t("Enter Package Purchase Number")}
                    value={values.purchaseNo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPaste={(evt) => evt.preventDefault()} 
                    // style={{ width: "80%" }}
                  />

                  <ErrorMessage
                    component="div"
                    name="purchaseNumber"
                    className="text-danger"
                  />
                </>
              ) : (
                <>
                  <div className="">
                    <BForm.Control
                      type="number"
                      name="purchaseNumber"
                      min="0"
                      disabled="true"
                      // style={{ width: "80%" }}
                    />

                    <ErrorMessage
                      component="div"
                      name="purchaseNumber"
                      className="text-danger"
                    />
                  </div>
                </>
              )}
              </div>
            </div>}
          </Row>
          <Row className="mt-2 ">
            {!values?.isLadderPackage  && <div className="col-12 col-lg-6">
              <BForm.Label>
                Package Name
                <span className="text-danger"> *</span>
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-package-name">
                    Enter the name of the package.
                  </Tooltip>
                }
              >
                <BForm.Control
                  type="text"
                  name="packageName"
                  placeholder={t("Package Name")}
                  value={values?.packageName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={(evt) =>
                    ["+", "-", "."].includes(evt.key) && evt.preventDefault()
                  }
                  // style={{ width: "80%" }}
                />
              </OverlayTrigger>

              <ErrorMessage
                component="div"
                name="packageName"
                className="text-danger"
              />
            </div>}

            <div className="col-12 col-lg-6">
              <BForm.Label>
                Package purchase limit per user
                <span className="text-danger"> *</span>
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    Enter zero if you do not want to add a limit.
                  </Tooltip>
                }
              >
                <BForm.Control
                  type="number"
                  name="purchaseLimitPerUser"
                  packageFirstPurchaseId
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t(
                    "createPackage.inputFields.purchaseLimitPerUser.placeholder"
                  )}
                  value={values.purchaseLimitPerUser}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.welcomePurchaseBonusApplicable}
                  // style={{ width: "80%" }}
                />
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="purchaseLimitPerUser"
                className="text-danger"
              />
            </div>
          </Row>
        </div>

        <div className="container-fluid">
          {values?.welcomePurchaseBonusApplicable && (
            <Row className="mt-3">
              <Col>
                <BForm.Label>
                  Timer
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-timer">
                      Set the timer for special purchases in minutes.
                    </Tooltip>
                  }
                >
                  <BForm.Control
                    type="number"
                    name="welcomePurchaseBonusApplicableMinutes"
                    min="0"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-", "."].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    placeholder={t(
                      "createPackage.inputFields.welcomePurchaseBonusApplicableMinutes.placeholder"
                    )}
                    value={values.welcomePurchaseBonusApplicableMinutes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.welcomePurchaseBonusApplicable}
                  />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="welcomePurchaseBonusApplicableMinutes"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>
                  Welcome Purchase Percentage
                  <span className="text-danger"> *</span>
                </BForm.Label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-welcome-percentage">
                      Enter the percentage for welcome purchases.
                    </Tooltip>
                  }
                >
                  <BForm.Control
                    type="number"
                    name="welcomePurchasePercentage"
                    min="0"
                    placeholder={"Welcome Purchase Percentage"}
                    value={values.welcomePurchasePercentage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.welcomePurchaseBonusApplicable}
                  />
                </OverlayTrigger>
                <ErrorMessage
                  component="div"
                  name="welcomePurchasePercentage"
                  className="text-danger"
                />
              </Col>
            </Row>
          )}
        </div>
        {!values?.isLadderPackage  && <div className="container-fluid">
          <Row className="mt-3">
            <Col>
              <BForm.Label>
                {`${t("createPackage.inputFields.amountField.label")} ( $ )`}
                <span className="text-danger"> *</span>
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-amount">
                    Enter the amount for the package.
                  </Tooltip>
                }
              >
                <BForm.Control
                  type="number"
                  name="amount"
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t(
                    "createPackage.inputFields.amountField.placeholder"
                  )}
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="amount"
                className="text-danger"
              />
            </Col>
            <Col>
              <BForm.Label>
                {t("createPackage.inputFields.gcField.label")}
                <span className="text-danger"> *</span>
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-amount">gc coin</Tooltip>}
              >
                <BForm.Control
                  type="number"
                  name="gcCoin"
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t(
                    "createPackage.inputFields.gcField.placeholder"
                  )}
                  value={values.gcCoin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="gcCoin"
                className="text-danger"
              />
            </Col>
            <Col>
              <BForm.Label>
                {t("createPackage.inputFields.scField.label")}
                <span className="text-danger"> *</span>
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-amount">sc coin</Tooltip>}
              >
                <BForm.Control
                  type="number"
                  name="scCoin"
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t(
                    "createPackage.inputFields.scField.placeholder"
                  )}
                  value={values.scCoin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="scCoin"
                className="text-danger"
              />
            </Col>

            {/* {!values?.firstPurchaseApplicable && ( */}
            <Col>
              <BForm.Label>
                {t("createPackage.inputFields.bonusSc.label")}
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bonus-sc">sc bonus</Tooltip>}
              >
                <BForm.Control
                  type="number"
                  name="bonusSc"
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t(
                    "createPackage.inputFields.bonusSc.placeholder"
                  )}
                  value={values.bonusSc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="bonusSc"
                className="text-danger"
              />
            </Col>
            
            <Col>
              <BForm.Label>
                {t("createPackage.inputFields.bonusGc.label")}
              </BForm.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-bonus-sc">gc bonus</Tooltip>}
              >
                <BForm.Control
                  type="number"
                  name="bonusGc"
                  min="0"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={t(
                    "createPackage.inputFields.bonusGc.placeholder"
                  )}
                  value={values.bonusGc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
              </OverlayTrigger>
              <ErrorMessage
                component="div"
                name="bonusGc"
                className="text-danger"
              />
            </Col>
            {/* )} */}
          </Row>
        </div>}

        <div className="container-fluid">
          <Row className="mt-3 justify-content-between">
            <Col>
              <Row>
                {(!values?.welcomePurchaseBonusApplicable && !values?.isLadderPackage) && (
                  <div className="col-12 col-lg-4">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-special-package">
                          This is a special package.
                        </Tooltip>
                      }
                    >
                      <div
                        className="d-flex align-items-center  rounded p-2 justify-content-between"
                        style={{ border: "0.0625rem solid #d1d7e0" }}
                      >
                        <p className="mb-0">
                          {t("createPackage.inputFields.specialPackage")}
                          <span className="text-danger"> *</span>
                        </p>

                        <BForm.Check
                          name="isSpecialPackage"
                          // placeholder='Enter User Name'
                          checked={values.isSpecialPackage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={values.welcomePurchaseBonusApplicable}
                        />
                      </div>
                    </OverlayTrigger>
                    <ErrorMessage
                      component="div"
                      name="isSpecialPackage"
                      className="text-danger"
                    />
                  </div>
                )}
                <div className="col-12 col-lg-4">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-active">
                        {values.isActive
                          ? "The package is currently active."
                          : "The package is currently inactive."}
                      </Tooltip>
                    }
                  >
                    <div
                      className="d-flex align-items-center  rounded p-2 justify-content-between"
                      style={{ border: "0.0625rem solid #d1d7e0" }}
                    >
                      <p className="mb-0">
                        {t("createPackage.inputFields.active")}
                        <span className="text-danger"> *</span>
                      </p>
                      <BForm.Check
                        name="isActive"
                        className="ml-2"
                        checked={values.isActive}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </div>
                  </OverlayTrigger>
                  <ErrorMessage
                    component="div"
                    name="isActive"
                    className="text-danger"
                  />
                </div>
                {values?.welcomePurchaseBonusApplicable !== true && (
                  <div className="col-12 col-lg-4">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-visible-store">
                          {values.isVisibleInStore
                            ? "The package is visible in the store."
                            : "Select package to make it visible in the store."}
                        </Tooltip>
                      }
                    >
                      <div
                        className="d-flex align-items-center    rounded p-2 justify-content-between "
                        style={{ border: "0.0625rem solid #d1d7e0" }}
                      >
                        <p className="mb-0">
                          {t("createPackage.inputFields.visibleInStore")}
                          <span className="text-danger"> *</span>
                        </p>

                        <BForm.Check
                          name="isVisibleInStore"
                          // placeholder='Enter User Name'
                          checked={values.isVisibleInStore}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </OverlayTrigger>
                    <ErrorMessage
                      component="div"
                      name="isVisibleInStore"
                      className="text-danger"
                    />
                  </div>
                )}
              </Row>
            </Col>

            {
              <div className="container-fluid">
                <Row className="mt-3">
                  {!values?.welcomePurchaseBonusApplicable && (
                    <div className="col-12 col-lg-6" border="primary">
                      <Row className="mt-3" style={{ position: "relative" }}>
                        <div className="col-12 col-lg-12">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-valid-from">
                                Starts Validity
                              </Tooltip>
                            }
                          >
                            <div className="d-flex  gap-5 align-items-center justify-content-between">
                              <h5 className="m-0 " style={{ fontSize: "18px" }}>
                                Valid From
                              </h5>
                              <div className="relative flex-grow-1">
                                <BForm.Check
                                  type="switch"
                                  name="isValidFrom"
                                  checked={values.isValidFrom}
                                  // onChange={handleChange}
                                  onChange={handleCheckBoxChange}
                                  onBlur={handleBlur}
                                  disabled={isEdit}
                                  style={{
                                    position: "absolute",
                                    top: "9px",
                                    left: "auto",
                                    right: "10px",
                                    zIndex: 2,
                                  }}
                                />

                                <ErrorMessage
                                  component="div"
                                  name="isValidFrom"
                                  className="text-danger"
                                />
                                {values.isValidFrom === true ? (
                                  <>
                                    <div className="">
                                      <Datetime
                                        inputProps={{
                                          placeholder: "MM-DD-YYYY HH:MM",
                                        }}
                                        dateFormat="MM-DD-YYYY"
                                        onChange={(e) => {
                                          setFieldValue("validFrom", e);
                                        }}
                                        value={
                                          values.validFrom
                                            ? getDateTime(values.validFrom)
                                            : values.validFrom
                                        }
                                        isValidDate={(e) => {
                                          return (
                                            e._d > yesterday ||
                                            getDateTimeByYMD(e._d) ===
                                              getDateTimeByYMD(new Date())
                                          );
                                        }}
                                        timeFormat={true}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="validFrom"
                                        className="text-danger"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="">
                                      <BForm.Control
                                        type="number"
                                        name="validFrom"
                                        min="0"
                                        disabled="true"
                                      />

                                      <ErrorMessage
                                        component="div"
                                        name="validFrom"
                                        className="text-danger"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </Row>

                      <Row className="mt-3" style={{ position: "relative" }}>
                        <div className="col-12 col-lg-12">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-valid-until">
                                Ends Validity
                              </Tooltip>
                            }
                          >
                            <div className="d-flex  gap-5 align-items-center justify-content-between">
                              <h5 className="m-0 " style={{ fontSize: "18px" }}>
                                Valid Until
                              </h5>
                              <div className="relative flex-grow-1">
                                <BForm.Check
                                  type="switch"
                                  name="isValidUntil"
                                  checked={values.isValidUntil}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={isEdit}
                                  style={{
                                    position: "absolute",
                                    top: "9px",
                                    left: "auto",
                                    right: "10px",
                                    zIndex: 2,
                                  }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="isValidUntil"
                                  className="text-danger"
                                />
                                {values.isValidUntil === true ? (
                                  <>
                                    <div className="">
                                      <Datetime
                                        inputProps={{
                                          placeholder: "MM-DD-YYYY HH:MM",
                                        }}
                                        dateFormat="MM-DD-YYYY"
                                        onChange={(e) => {
                                          setFieldValue("validTill", e);
                                        }}
                                        value={
                                          values.validTill
                                            ? getDateTime(values.validTill)
                                            : values.validTill
                                        }
                                        isValidDate={(e) => {
                                          return (
                                            e._d > yesterday ||
                                            getDateTimeByYMD(e._d) ===
                                              getDateTimeByYMD(new Date())
                                          );
                                        }}
                                        timeFormat={true}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="validTill"
                                        className="text-danger"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="">
                                      <BForm.Control
                                        type="number"
                                        name="validTill"
                                        min="0"
                                        disabled="true"
                                      />

                                      <ErrorMessage
                                        component="div"
                                        name="validTill"
                                        className="text-danger"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </Row>
                      <div className="d-flex align-items-center mt-2" style={{ gap: "16px" }}>
                        <Button
                          variant="primary"
                          onClick={() => handleAddHours(setFieldValue,values.validFrom, 12)}
                          disabled={!values.isValidUntil}
                          className="m-2"
                        >
                          {t("createPackage.inputFields.12Hour")}
                        </Button>

                        <Button
                          variant="primary"
                          onClick={()=> handleAddHours(setFieldValue, values.validFrom,24)}
                          disabled={!values.isValidUntil}
                          className="ml-4"
                        >
                          {t("createPackage.inputFields.24Hour")}
                        </Button>
                      </div>
                    </div>
                  )}

                  <div
                    className="col-12 col-lg-6  rounded  "
                    style={{
                      position: "relative",
                      border: "0.0625rem solid #d1d7e0  ",
                      minHeight: "120px",
                    }}
                  >
                    <BForm.Label className="relative mt-3">
                      Package Image
                      <span className="text-danger"> *</span>
                    </BForm.Label>
                    <BForm.Text>
                      <Trigger
                        message={t(
                          "createPackage.inputFields.thumbnail.message"
                        )}
                        id={"mes"}
                      />

                      <Button
                        className="file-upload-button"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          top: "auto",
                          left: "auto",
                          right: "15px",
                        }}
                      >
                        Choose File
                        <input
                          id={"mes"}
                          title=" "
                          name="image"
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            width: "100%",
                            right: "2px",
                          }}
                          onChange={(event) => {
                            setFieldValue(
                              "image",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </Button>
                      {values?.image && (
                        <img
                          alt="not found"
                          width="200px"
                          height="100px"
                          src={URL.createObjectURL(values.image)}
                          style={{ marginTop: "10px" }}
                        />
                      )}
                      {!values?.image && packageData?.imageUrl && (
                        <img
                          alt="not found"
                          width="60px"
                          src={packageData.imageUrl}
                          style={{
                            height: "120px",
                            width: "230px",
                            marginTop: "1%",
                          }}
                        />
                      )}
                    </BForm.Text>

                    <ErrorMessage
                      component="div"
                      name="image"
                      className="text-danger"
                    />
                  </div>
                </Row>
              </div>
            }
          </Row>
        </div>
        {/* {values?.firstPurchaseApplicable && (
          <div className="container-fluid">
            <Row
              className="rounded mt-2 p-2"
              style={{ border: "0.0625rem solid #d1d7e0" }}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-add-bonus">
                    Add a bonus for first purchases.
                  </Tooltip>
                }
              >
                <Row>
                  <Button
                    variant="outline-secondary"
                    className="f-right ml-2"
                    onClick={handleButtonClick}
                    hidden={showRows}
                    style={{ width: "150px", left: "10px" }}
                  >
                    Add Bonus
                  </Button>
                  {values?.firstPurchaseApplicable &&
                    isEditPage &&
                    !showRows && (
                      <p>
                        *Adding bonuses will change package type to First
                        Purchase Package
                      </p>
                    )}
                </Row>
              </OverlayTrigger>
              {showRows && (
                <>
                  <Row className="mt-2">
                    <Col className="col-12 col-sm-6">
                      <BForm.Label>SC BONUS</BForm.Label>
                      <BForm.Control
                        type="number"
                        min="1"
                        placeholder="SC"
                        name="sc"
                        value={formData.sc}
                        onChange={handleBonusChange}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onPaste={(evt) => evt.preventDefault()}
                      />
                      <ErrorMessage
                        component="div"
                        name="bonusAmountGc"
                        className="text-danger"
                      />
                    </Col>

                    <Col className="col-12 col-sm-6">
                      <BForm.Label>GC BONUS</BForm.Label>
                      <BForm.Control
                        type="number"
                        min="1"
                        placeholder="GC"
                        name="gc"
                        value={formData.gc}
                        onChange={handleBonusChange}
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onPaste={(evt) => evt.preventDefault()}
                      />
                      <ErrorMessage
                        component="div"
                        name="bonusAmountSc"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  {!isEditPage ? (
                    <div className="mt-4 d-flex justify-content-between align-items-center">
                      <Button variant="warning" onClick={handleCancel}>
                        Cancel
                      </Button>

                      <div>
                        <Button
                          variant="warning"
                          className="m-1"
                          onClick={handleReset}
                          style={{ width: "80px" }}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="success"
                          onClick={handleBonusAdd}
                          style={{ width: "80px" }}
                          className="m-1"
                          disabled={
                            Object.keys(formData).length === 0 ||
                            (formData.gc === "" && formData.sc === "") ||
                            (Number(formData.gc) === 0 &&
                              Number(formData.sc) === 0)
                          }
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 d-flex justify-content-between align-items-center">
                      <Button variant="warning" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        variant="success"
                        onClick={() =>
                          handlecreateFtpSubmit({
                            packageId: Number(values?.packageId),
                            firstPurchaseScBonus: formData.sc
                              ? Number(formData.sc)
                              : 0,
                            firstPurchaseGcBonus: formData.gc
                              ? Number(formData.gc)
                              : 0,
                            firstPurchaseApplicable:
                              !values?.firstPurchaseApplicable,
                          })
                        }
                        style={{ width: "140px" }}
                        className="m-1"
                        disabled={
                          createFtpBonusloading ||
                          (formData.gc === "" && formData.sc === "") ||
                          Object.keys(formData).length === 0 ||
                          (Number(formData.gc) === 0 &&
                            Number(formData.sc) === 0)
                        }
                      >
                        Add Bonus
                        {createFtpBonusloading && (
                          <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </div>
                  )}

                  {!isEditPage && bonuses.length > 0 && ( 
                    <Table
                      bordered
                      striped
                      responsive
                      hover
                      size="sm"
                      className="text-center mt-4"
                    >
                      <thead className="thead-dark">
                        <tr>
                          {["SCBONUS", "GCBONUS"].map((h) => (
                            <th key={h}>{h}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {bonuses?.map((bonus, index) => {
                          return (
                            <tr key={index}>
                              <td>{bonus.sc}</td>
                              <td>{bonus.gc}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </>
              )}
            </Row>
          </div>
        )} */}

        {
          values?.isLadderPackage && <>
          
          <div className="container-fluid">
          <Row className="rounded mt-2 p-2" style={{ border: "0.0625rem solid #d1d7e0" }}>
            <Button
              variant="outline-primary"
              className="f-right ml-2"
              onClick={handleButtonClick}
              hidden={showRows}
            >
              Add Packages
            </Button>

            {showRows && (
              <div className="container-fluid">
           
                <Row className="mt-3">
                  <Col md={6}>
                    <BForm.Label>
                      Package Name<span className="text-danger"> *</span>
                    </BForm.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-package-name">Enter the name of the package.</Tooltip>}
                    >
                      <BForm.Control
                        type="text"
                        name="packageName"
                        placeholder={t("Package Name")}
                        value={values.packageName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={(evt) =>
                          ["+", "-", "."].includes(evt.key) && evt.preventDefault()
                        }
                      />
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="packageName" className="text-danger" />
                  </Col>

                  <Col md={3}>
                    <BForm.Label>
                      {`${t("createPackage.inputFields.amountField.label")} ( $ )`}
                      <span className="text-danger"> *</span>
                    </BForm.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-amount">Enter the amount for the package.</Tooltip>}
                    >
                      <BForm.Control
                        type="number"
                        name="amount"
                        min="0"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                        }
                        placeholder={t("createPackage.inputFields.amountField.placeholder")}
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="amount" className="text-danger" />
                  </Col>

                  <Col md={3}>
                    <BForm.Label>
                      {t("createPackage.inputFields.gcField.label")}
                      <span className="text-danger"> *</span>
                    </BForm.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-gc">Enter the GC value.</Tooltip>}
                    >
                      <BForm.Control
                        type="number"
                        name="gcCoin"
                        min="0"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
                        }
                        placeholder={t("createPackage.inputFields.gcField.placeholder")}
                        value={values.gcCoin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="gcCoin" className="text-danger" />
                  </Col>
                </Row>
                <Row className="mt-3 d-flex align-items-end">
                  <Col md={3}>
                    <BForm.Label>
                      {t("createPackage.inputFields.scField.label")}
                      <span className="text-danger"> *</span>
                    </BForm.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-sc">Enter the SC value.</Tooltip>}
                    >
                      <BForm.Control
                        type="number"
                        name="scCoin"
                        min="0"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
                        }
                        placeholder={t("createPackage.inputFields.scField.placeholder")}
                        value={values.scCoin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="scCoin" className="text-danger" />
                  </Col>

                  <Col md={3}>
                    <BForm.Label>{t("createPackage.inputFields.bonusSc.label")}</BForm.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-bonus-sc">Enter SC bonus value.</Tooltip>}
                    >
                      <BForm.Control
                        type="number"
                        name="bonusSc"
                        min="0"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
                        }
                        placeholder={t("createPackage.inputFields.bonusSc.placeholder")}
                        value={values.bonusSc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="bonusSc" className="text-danger" />
                  </Col>

                  <Col md={3}>
                    <BForm.Label>{t("createPackage.inputFields.bonusGc.label")}</BForm.Label>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-bonus-gc">Enter GC bonus value.</Tooltip>}
                    >
                      <BForm.Control
                        type="number"
                        name="bonusGc"
                        min="0"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
                        }
                        placeholder={t("createPackage.inputFields.bonusGc.placeholder")}
                        value={values.bonusGc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isEdit}
                      />
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="bonusGc" className="text-danger" />
                  </Col>

                  <Col md={3}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-special-package">This is a special package.</Tooltip>
                      }
                    >
                      <div
                        className="d-flex align-items-center rounded p-2 justify-content-between"
                        style={{ border: "0.0625rem solid #d1d7e0" }}
                      >
                        <p className="mb-0">
                          {t("createPackage.inputFields.specialPackage")}
                          <span className="text-danger"> *</span>
                        </p>
                        <BForm.Check
                          name="isSpecialPackage"
                          checked={values.isSpecialPackage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={values.welcomePurchaseBonusApplicable}
                        />
                      </div>
                    </OverlayTrigger>
                    <ErrorMessage component="div" name="isSpecialPackage" className="text-danger" />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <div className="mt-4 d-flex justify-content-between align-items-center">
                        <Button variant="warning" onClick={handleCancel}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick = {handleAddLadderPackages}
                          // onClick={ }
                          // style={{ width: "140px" }}
                          // className="m-1"
                          // disabled={
                          //   createFtpBonusloading ||
                          //   (formData.gc === "" && formData.sc === "") ||
                          //   Object.keys(formData).length === 0 ||
                          //   (Number(formData.gc) === 0 &&
                          //     Number(formData.sc) === 0)
                          // }
                          disabled={
                            values?.packageName === "" || values?.amount === "" || values?.amount <=0
                            || values?.gcCoin === "" || values?.scCoin === ""
                          }
                        >
                          Add Package
                          {/* {createFtpBonusloading && (
                            <Spinner
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                            />
                          )} */}
                        </Button>
                  </div>
                </Row>

                {
                  ladderPackageList?.length > 0 && 
                  <Table
                      bordered
                      striped
                      responsive
                      hover
                      size="sm"
                      className="text-center mt-4"
                    >
                      <thead className="thead-dark">
                        <tr>
                          {["PACKAGE NAME", "AMOUNT", "GC COIN", "SC COIN", "GC bONUS", "SC BONUS","SPECIAL PACKAGE", "ACTION"].map((h) => (
                            <th key={h}>{h}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {ladderPackageList?.map((pkg, index) => {
                          return (
                            <tr key={index}>
                              <td>{pkg.packageName}</td>
                              <td>{pkg.amount}</td>
                              <td>{pkg.gcCoin}</td>
                              <td>{pkg.scCoin}</td>
                              <td>{pkg.gcBonus}</td>
                              <td>{pkg.scBonus}</td>
                              <td>{pkg.isSpecialPackage ? "Yes" : "No"}</td>
                              <td>
                                  <Trigger
                                  message="Delete"
                                  id={pkg?.packageName + "delete"}
                                />
                                <Button
                                  id={pkg?.packageName  + "delete"}
                                  className="m-1"
                                  size="sm"
                                  variant="danger"
                                  onClick={() =>
                                    handleDeletePackage(pkg.packageName)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                }
              </div>
    )}
  </Row>

  <ErrorMessage component="div" name="ladderPackageData" className="text-danger"/>
</div>

          </>
        }

        <div className="container-fluid">
          <div
            className="mt-3  mb-2 d-flex  align-items-center"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              variant="warning"
              onClick={() => navigate(AdminRoutes.Packages)}
              className="m-2 btn btn-secondary "
            >
              {t("createPackage.cancelButton")}
            </Button>

            <Button
              variant="success"
              onClick={handleSubmit}
              className="  ml-4"
              disabled={
                loading 
                // (values?.firstPurchaseApplicable &&
                //   (!Array.isArray(bonusdata) || bonusdata.length === 0) &&
                //   (!Array.isArray(bonuses) || bonuses.length === 0))
              }
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <>{t("createPackage.submitButton")}</>
              )}
            </Button>
          </div>
        </div>

        {/* {values?.firstPurchaseApplicable &&
          isEditPage &&
          values?.packageFirstPurchase.length > 0 && (
            <div className="container-fluid">
              <Row>
                <Col xs="9">
                  <h3>FIRST PURCHASE BONUS</h3>
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="success"
                    className="mb-2 m-1"
                    size="sm"
                    //hidden={isHidden({ module: { key: 'Package', value: 'U' } })}

                    onClick={() =>
                      navigate(
                        `${AdminRoutes.ReorderFtpBonus.split(":")[0]}${
                          values?.packageId
                        }/${values?.firstPurchaseApplicable}`
                      )
                    }
                  >
                    Reorder
                  </Button>
                </Col>
              </Row>

              <Row
                className="  rounded  pt-5 pb-5 pl-2 pr-2 "
                style={{ border: "0.0625rem solid #d1d7e0" }}
              >
                <Table
                  bordered
                  striped
                  responsive
                  hover
                  size="sm"
                  className="text-center mt-4"
                >
                  <thead className="thead-dark">
                    <tr>
                      {["ID", "SCBONUS", "GCBONUS", "STATUS", "ACTIONS"].map(
                        (h) => (
                          <th key={h}>{h}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {bonusdata?.map((bonus, index) => (
                      <tr key={index}>
                        <td>{bonus.packageFirstPurchaseId}</td>
                        <td>
                          {editRowId === bonus?.packageFirstPurchaseId ? (
                            <BForm.Control
                              type="number"
                              name="scAmount"
                              min="0"
                              step="any"
                              value={editValues.firstPurchaseScBonus || ""}
                              onChange={(e) =>
                                handleftpChange(e, "firstPurchaseScBonus")
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "."].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onPaste={(evt) => evt.preventDefault()}
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d*\.?\d*$/.test(value)) {
                                  e.target.value = "";
                                }
                              }}
                            />
                          ) : (
                            bonus.firstPurchaseScBonus
                          )}
                        </td>
                        <td>
                          {editRowId === bonus?.packageFirstPurchaseId ? (
                            <BForm.Control
                              type="number"
                              name="scAmount"
                              min="0"
                              step="any"
                              value={editValues.firstPurchaseGcBonus || ""}
                              onChange={(e) =>
                                handleftpChange(e, "firstPurchaseGcBonus")
                              }
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "."].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onInput={(e) => {
                                const value = e.target.value;
                                if (!/^\d*\.?\d*$/.test(value)) {
                                  e.target.value = "";
                                }
                              }}
                              onPaste={(evt) => evt.preventDefault()}
                            />
                          ) : (
                            bonus.firstPurchaseGcBonus
                          )}
                        </td>
                        <td>
                          {" "}
                          {bonus?.isActive ? (
                            <span className="text-success">Active</span>
                          ) : (
                            <span className="text-danger">In Active</span>
                          )}
                        </td>
                        <td>
                          {editRowId === bonus?.packageFirstPurchaseId ? (
                            <>
                              <Trigger
                                message={"Save"}
                                id={bonus?.packageFirstPurchaseId + "save"}
                              />
                              <Button
                                id={bonus?.packageFirstPurchaseId + "save"}
                                className="m-1"
                                size="sm"
                                variant="warning"
                                onClick={() =>
                                  handleFtpSubmit({
                                    packageId: Number(values?.packageId),
                                    firstPurchaseScBonus: Number(
                                      editValues?.firstPurchaseScBonus
                                    ),
                                    firstPurchaseGcBonus: Number(
                                      editValues?.firstPurchaseGcBonus
                                    ),
                                    packageFirstPurchaseId: Number(
                                      bonus?.packageFirstPurchaseId
                                    ),
                                  })
                                }
                                disabled={updataFtpbonusloading}
                              >
                                <FontAwesomeIcon icon={faSave} />
                                {updataFtpbonusloading && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                              </Button>
                              <Trigger
                                message={"Cancel"}
                                id={bonus?.packageFirstPurchaseId + "cancel"}
                              />

                              <Button
                                id={bonus?.packageFirstPurchaseId + "cancel"}
                                className="m-1"
                                size="sm"
                                variant="warning"
                                onClick={() => setEditRowId(null)}
                              >
                                <FontAwesomeIcon icon={faCancel} />
                              </Button>
                            </>
                          ) : (
                            <>
                              <Trigger
                                message={"Edit"}
                                id={bonus?.packageFirstPurchaseId + "edit"}
                              />
                              <Button
                                id={bonus?.packageFirstPurchaseId + "edit"}
                                className="m-1"
                                size="sm"
                                variant="warning"
                                onClick={() => handleEditClick(bonus)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>

                              {!bonus.isActive ? (
                                <>
                                  <Trigger
                                    message="Set Status Active"
                                    id={bonus.packageFirstPurchaseId + "active"}
                                  />
                                  <Button
                                    id={bonus.packageFirstPurchaseId + "active"}
                                    className="m-1"
                                    size="sm"
                                    variant="success"
                                    onClick={() =>
                                      handleShow(
                                        values?.packageId,
                                        bonus?.packageFirstPurchaseId,
                                        bonus?.isActive
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Trigger
                                    message="Set Status In-Active"
                                    id={
                                      bonus.packageFirstPurchaseId + "inactive"
                                    }
                                  />
                                  <Button
                                    id={
                                      bonus.packageFirstPurchaseId + "inactive"
                                    }
                                    className="m-1"
                                    size="sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleShow(
                                        values?.packageId,
                                        bonus?.packageFirstPurchaseId,
                                        bonus?.isActive
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                          <>
                            <Trigger
                              message="Delete"
                              id={bonus?.packageFirstPurchaseId + "delete"}
                            />
                            <Button
                              id={bonus?.packageFirstPurchaseId + "delete"}
                              className="m-1"
                              size="sm"
                              variant="danger"
                              onClick={() =>
                                handleDeleteModal(
                                  values?.packageId,
                                  bonus?.packageFirstPurchaseId
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </div>
          )} */}

        {!values?.welcomePurchaseBonusApplicable && (
          <div className="container-fluid">
            <Row
              className="  rounded  pt-5 pb-5 pl-2 pr-2 "
              style={{ border: "0.0625rem solid #d1d7e0" }}
            >
              <Col>
                <BForm.Label>Package User Filter</BForm.Label>
                <BForm.Select
                  as="select"
                  name="filterType"
                  value={values.filterType}
                  onChange={handleFilterChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled selected hidden>
                    Select Filter Type
                  </option>
                  {Object.entries(PACKAGE_USER_FILTER).map(([label, val]) => (
                    <option key={val} value={val}>
                      {label}
                    </option>
                  ))}
                </BForm.Select>
                <ErrorMessage
                  component="div"
                  name="filterType"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Operator</BForm.Label>
                <BForm.Select
                  as="select"
                  name="filterOperator"
                  value={values.filterOperator}
                  onChange={handleFilterChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled selected hidden>
                    Select Operator
                  </option>
                  <option value="=">=</option>
                  <option value=">">&gt;</option>
                  <option value="<">&lt;</option>
                  <option value=">=">&gt;=</option>
                  <option value="<=">&lt;=</option>
                </BForm.Select>
                <ErrorMessage
                  component="div"
                  name="filterOperator"
                  className="text-danger"
                />
              </Col>
              <Col>
                <BForm.Label>Value</BForm.Label>
                <BForm.Control
                  type="number"
                  onKeyDown={(evt) =>
                    ["e", "E", "+"].includes(evt.key) && evt.preventDefault()
                  }
                  name="filterValue"
                  value={values.filterValue}
                  onChange={handleFilterChange}
                  placeholder="Enter Filter Value"
                  onBlur={handleBlur}
                  disabled={isEdit}
                />
                <ErrorMessage
                  component="div"
                  name="filterValue"
                  className="text-danger"
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <BForm.Label>User Details</BForm.Label>
              </Col>

              {data && (
                <Col md={1}>
                  <Trigger message="Download as CSV" id={"csv"} />
                  <Button
                    id={"csv"}
                    variant="success"
                    disabled={data?.playerIds?.length === 0}
                    onClick={handleDownloadClick}
                  >
                    {downloadInProgress ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <FontAwesomeIcon icon={faFileDownload} />
                    )}
                  </Button>
                </Col>
              )}
              {selectedUserIds?.length > 0 && (
                <Col md={2}>
                  <Button
                    variant="primary"
                    onClick={() => setSelectedUserIds([])}
                    style={{ width: "100px" }}
                  >
                    Clear
                  </Button>
                </Col>
              )}

              <div>
                {loadingData ? (
                  <InlineLoader />
                ) : (
                  data !== undefined && (
                    <Table
                      bordered
                      striped
                      hover
                      size="sm"
                      className="text-center mt-4"
                      style={{ marginTop: "28px" }}
                    >
                      <thead className="thead-dark">
                        <tr>
                          <th>
                            <input
                              name="selectAll"
                              type="checkbox"
                              className="form-check-input cursor-pointer"
                              onChange={handleSelectAll}
                              checked={data?.playerIds?.every((user) =>
                                selectedUserIds.includes(user.userId)
                              )}
                            />
                          </th>
                          <th>User ID</th>
                          {values.filterType == "ngr" && <th>value</th>}
                          <th>Email</th>
                          <th>Username</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.playerIds?.length > 0 ? (
                          data?.playerIds?.map((user) => {
                            return (
                              <tr key={user.userId}>
                                <td>
                                  <input
                                    name="select"
                                    type="checkbox"
                                    className="form-check-input cursor-pointer"
                                    checked={selectedUserIds.includes(
                                      user.userId
                                    )}
                                    onChange={(e) =>
                                      handleSelectUser(e, user.userId)
                                    }
                                  />
                                </td>
                                <td>{user.userId}</td>
                                {values.filterType == "ngr" && (
                                  <td>{user.value}</td>
                                )}
                                <td>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      navigate(
                                        `${AdminRoutes.PlayerDetails.split(
                                          ":"
                                        ).shift()}${user.userId}`
                                      );
                                    }}
                                  >
                                    {user.email}
                                  </a>
                                </td>
                                <td>{user.username}</td>
                                <td>{user.isActive ? "Yes" : "No"}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={9} className="text-danger text-center">
                              {t("noDataFound")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )
                )}

                {packageDataLoading && data !== undefined && <InlineLoader />}
              </div>
            </Row>
          </div>
        )}
        {/* : (
              <p className="text-danger text-center mt-3">No Data Available</p>
            ) */}
        {/* <ColorPickerForm /> */}
      </Form>
      
    {/* } */}



      {/* {data?.playerIds.length !== 0 &&
        (
          <PaginationComponent
            page={data?.playerIds.length  < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />

        )} */}
      {deleteModalShow && (
        <DeleteConfirmationModal
          deleteModalShow={deleteModalShow}
          setDeleteModalShow={setDeleteModalShow}
          handleDeleteYes={handleDeleteYes}
          loading={deleteftploading}
        />
      )}
      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          loading={statusFtploading}
        />
      )}
    </>
  );
};

export default PackageCreateForm;
