import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TotaPlayerScoreChart = ({ labels, scoreData }) => {
  const data = {
    labels: labels, // X-axis labels
    datasets: [
      {
        label: "Score", // Label for the first bar
        data: scoreData, // Y-axis data points
        backgroundColor: "rgba(38, 43, 64)", // Bar color
        borderColor: "rgba(38, 43, 64)", // Border color
        borderWidth: 1, // Border width
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top", // Position of the legend
      },
      title: {
        display: true,
        text: "Total score", // Chart title
        font: {
          weight: 'bold', // Make the X-axis label bold
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Username", 
          font: {
            weight: 'bold', // Make the X-axis label bold
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Score", // Label for Y-axis
          font: {
            weight: 'bold', // Make the X-axis label bold
          },
        },
      },
    },
  };

  return (
    <div style={{ minHeight: "250px" }}>
      <h2>Total score of players</h2>
      <Line data={data} options={options} />
    </div>
  );
};

export default TotaPlayerScoreChart;
