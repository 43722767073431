import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner,
  ProgressBar,
  Tabs,
  Tab,
  Accordion,
  Card,
} from "@themesberg/react-bootstrap";
import PriceDistributionChart from "./PriceDistributionChart";
import PlayerCountByDateChart from "./PlayerCountByDateChart";
import TopTenPlayerChart from "./TopTenPlayerChart";
import TotaPlayerScoreChart from "./TotalPlayerScoreChart";
import TournamentStatisticsChart from "./TournamentStatisticsChart";
import useTournamentDashboardDetails from "../hooks/useTournamentDashboardDetails";
import TournamentAccordion from "./TournamentAccordion";
import {
  convertTimeZone,
  getFormattedTimeZoneOffset,
} from "../../../utils/helper";
import { getDateDaysAgo } from "../../../utils/dateFormatter";
import { timeZones } from "../../Dashboard/constants";
import { getItem } from "../../../utils/storageUtils";
import TournamentBootedAccordion from "./TournamentBootedAccordion";

const formatePriceDistributionData = (responseData = []) => {
  const labels = [];
  const gcData = [];
  const scData = [];

  responseData?.forEach(({ position, scCoin, gcCoin }) => {
    labels.push(position);
    gcData.push(gcCoin);
    scData.push(scCoin);
  });
  return { labels, gcData, scData };
};

const formateTopTenPlayerData = (responseData = []) => {
  const labels = [];
  const winData = [];
  const betData = [];

  responseData?.forEach(({ username, win, bet }) => {
    labels.push(username);
    winData.push(win);
    betData.push(bet);
  });
  return { labels, winData, betData };
};

const formatePlayerCountByJoinDateData = (responseData = {}) => {
  const labels = [];
  const countData = [];

  Object.keys(responseData).forEach((key) => {
    labels.push(key);
    countData.push(responseData[key]);
  });
  return { labels, countData };
};

const formatePlayerTotalScore = (responseData = []) => {
  const labels = [];
  const scoreData = [];

  responseData?.forEach(({ username, score }) => {
    labels.push(username);
    scoreData.push(score);
  });
  return { labels, scoreData };
};

const formateTournamentStatisticsData = (responseData = []) => {
  const labels = [];
  const playerBetData = [];
  const playerWonData = [];
  const ggrData = [];

  responseData.forEach((object) => {
    const { playerBet, playerWon, playerGGR, date } = object;
    labels.push(date);

    playerBetData.push(playerBet);
    playerWonData.push(playerWon);
    ggrData.push(playerGGR);
  });

  return { labels, playerBetData, playerWonData, ggrData };
};

const formatDropDownOptions = (responseData = {}) => {
  const options = [];

  Object.keys(responseData).forEach((key) => {
    options.push({ label: responseData[key], value: key });
  });
  return options;
};

const TournamentDashboard = ({ tournamentDashboardData, tournamentData }) => {
  const [priceDistributionData, setPriceDistributionData] = useState({
    labels: [],
    gcData: [],
    scData: [],
  });
  const [topTenPlayerData, setTopTenPlayerData] = useState({
    labels: [],
    winData: [],
    betData: [],
  });
  const [playerCountJoinByDateData, setplayerCountJoinByDateData] = useState({
    labels: [],
    countData: [],
  });
  const [totalPlayerScore, setTotalPlayerScore] = useState({
    labels: [],
    scoreData: [],
  });
  const [tournamentStatisticsData, setTournamentStatisticsData] = useState({
    labels: [],
    scData: [],
    gcData: [],
    ggrData: [],
  });
  const [gameOptions, setGameOptions] = useState([]);
  const [playerOptions, setPlayerOptions] = useState([]);

  const timeZone = getItem("timezone");
  const timezoneOffset =
    timeZone != null
      ? timeZones.find((x) => x.code === timeZone).value
      : getFormattedTimeZoneOffset();
  const [timeZoneCode, setTimeZoneCode] = useState(
    timeZones.find((x) => x.value === timezoneOffset)?.code
  );

  const {
    tournamentDashboardData: tournamentDashboardFilteredData,
    selectedGame,
    selectedPlayer,
  } = useTournamentDashboardDetails({});

  useEffect(() => {
    setTimeZoneCode(timeZones.find((x) => x.value === timezoneOffset)?.code);
  }, [timezoneOffset]);

  useEffect(() => {
    if (tournamentDashboardFilteredData) {
      const formattedTouranamentStatisticsData =
        formateTournamentStatisticsData(
          tournamentDashboardFilteredData?.data?.tournamentStatsByStartEndDate
        );
      setTournamentStatisticsData(formattedTouranamentStatisticsData);
    }
  }, [tournamentDashboardFilteredData]);

  useEffect(() => {
    if (tournamentDashboardData) {
      const data = tournamentDashboardData;
      const formattedPriceDistributionData = formatePriceDistributionData(
        data?.tournamentSummary?.priceDistribution
      );

      const formattedTopTenPlayerData = formateTopTenPlayerData(
        data?.top10Players
      );

      const formattedPlayerCountByJoinDateData =
        formatePlayerCountByJoinDateData(data?.playerCountJoinByDate);

      const formattedPlayerTotalScore = formatePlayerTotalScore(
        data?.totalScoreOfPlayers
      );

      const formattedGamesOption = formatDropDownOptions(
        data?.totalGameIdInTournament
      );

      const formattedPlayerOptions = formatDropDownOptions(
        data?.totalUserIdOfPlayers
      );

      setTopTenPlayerData(formattedTopTenPlayerData);

      setPriceDistributionData(formattedPriceDistributionData);

      setplayerCountJoinByDateData(formattedPlayerCountByJoinDateData);

      setTotalPlayerScore(formattedPlayerTotalScore);

      setGameOptions(formattedGamesOption);

      setPlayerOptions(formattedPlayerOptions);
    }
  }, [tournamentDashboardData]);

  function formatPriceWithCommas(price) {
    if (price) {
      const priceString = price.toString()
      const parts = priceString.split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    }
    return 0
  }

  return (
    <>
      <div className="tournament-detail-wrap">
        <Card className=" tournament-card p-2">
          <div>
            <h3>Tournament GGR</h3>
            <h3
              style={{
                color:
                  tournamentDashboardData?.tournamentSummary?.ggr < 0
                    ? "red"
                    : "green",
              }}
            >
              {formatPriceWithCommas(tournamentDashboardData?.tournamentSummary?.ggr)} {tournamentData?.entryCoin}
            </h3>
          </div>
          <div>
            <img src="/tournament-ggr.png" alt="ggr" />
          </div>
        </Card>
        <Card className=" tournament-card p-2">
          <div>
            <h3>Total Win</h3>
            <h3>
              {formatPriceWithCommas(tournamentDashboardData?.tournamentSummary?.totalWin)} {tournamentData?.entryCoin}
            </h3>
          </div>
          <div>
            <img src="/total-win.png" alt="ggr" />
          </div>
        </Card>
        <Card className=" tournament-card p-2">
          <div>
            <h3>Total Bet</h3>
            <h3>
              {formatPriceWithCommas(tournamentDashboardData?.tournamentSummary?.totalBet)} {tournamentData?.entryCoin}
            </h3>
          </div>
          <div>
            <img src="/total-bet.png" alt="ggr" />
          </div>
        </Card>
        <Card className=" tournament-card p-2">
          <div>
            <h3>Total Player Joined</h3>
            <h3>
              {formatPriceWithCommas(tournamentDashboardData?.tournamentSummary?.totalPlayerCount)}
            </h3>
          </div>
          <div>
            <img src="/player-joined.png" alt="ggr" />
          </div>
        </Card>
        <Card className=" tournament-card p-2">
          <div>
            <h3>Prize Pool SC</h3>
            <h3>
              {formatPriceWithCommas(tournamentDashboardData?.tournamentSummary?.pricePoolSc)}
            </h3>
          </div>
          <div>
            {" "}
            <img src="/pool-sc.png" alt="ggr" />
          </div>
        </Card>
        <Card className=" tournament-card p-2">
          <div>
            <h3>Prize Pool GC</h3>
            <h3>
              {formatPriceWithCommas(tournamentDashboardData?.tournamentSummary?.pricePoolGc)}
            </h3>
          </div>
          <div>
            {" "}
            <img src="/pool-gc.png" alt="ggr" />
          </div>
        </Card>
        <Card className=" tournament-card p-2">
          <div>
            <h3>Entry Amount</h3>
            <h3>
              {formatPriceWithCommas(tournamentData?.entryAmount)} {tournamentData?.entryCoin}
            </h3>
          </div>
          {tournamentData?.entryCoin === "SC" ? <div>
            <img src="/entry-amount-sc.png" alt="ggr" />
          </div> : <div>
            <img src="/entry-amount-gc.png" alt="ggr" />
          </div>}
        </Card>
      </div>
      <Row className="mt-4">
        <Row className="mt-0">
          <Col md={6} sm={6} className="mt-3">
            <Card className=" tournament-card p-2">
              <PriceDistributionChart
                labels={priceDistributionData?.labels}
                gcData={priceDistributionData?.gcData}
                scData={priceDistributionData?.scData}
              />
            </Card>
          </Col>
          <Col md={6} sm={6} className="mt-3">
            <Card className=" tournament-card p-2">
              <TopTenPlayerChart
                labels={topTenPlayerData?.labels}
                winData={topTenPlayerData?.winData}
                betData={topTenPlayerData?.betData}
              />
            </Card>
          </Col>
        </Row>

        <Row className="mt-0">
          <Col md={6} sm={6} className="mt-3">
            <Card className=" tournament-card p-2">
              <PlayerCountByDateChart
                labels={playerCountJoinByDateData.labels}
                countData={playerCountJoinByDateData.countData}
              />
            </Card>
          </Col>
          <Col md={6} sm={6} className="mt-3">
            <Card className=" tournament-card p-2">
              <TotaPlayerScoreChart
                labels={totalPlayerScore.labels}
                scoreData={totalPlayerScore.scoreData}
              />
            </Card>
          </Col>
        </Row>
        <Row className="mt-0">
          <Col md={12} sm={12} className="my-3">
            <Card className=" tournament-card statistics p-2">
              <TournamentStatisticsChart
                tournamentData={tournamentData}
                gameOptions={gameOptions}
                selectedGame={selectedGame}
                selectedPlayer={selectedPlayer}
                playerOptions={playerOptions}
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <TournamentBootedAccordion
            activeToggleHeader={"Booted Player"}
            list={tournamentDashboardData?.tournamentSummary?.bootedPlayers}
            tournamentData={tournamentData}
          />
        </Row>
        <Row>
          <TournamentAccordion
            activeToggleHeader={"Tournament Winners"}
            list={tournamentDashboardData?.tournamentSummary?.tournamentWinners}
            tournamentData={tournamentData}
          />
        </Row>
        <Row>
          <TournamentAccordion
            activeToggleHeader={"Top Positive GGR"}
            list={tournamentDashboardData?.tournamentSummary?.topPositiveGgr}
            tournamentData={tournamentData}
          />
        </Row>
        <Row>
          <TournamentAccordion
            activeToggleHeader={"Top Negative GGR"}
            list={tournamentDashboardData?.tournamentSummary?.topNegativeGgr}
            tournamentData={tournamentData}
          />
        </Row>
      </Row>
    </>
  );
};

export default TournamentDashboard;
