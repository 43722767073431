import React, { useEffect } from 'react'
import useTournamentDetails from '../hooks/useTournamentDetails'
import CreateTournament from './CreateTournament'
import Preloader from '../../../components/Preloader'

const EditTournament = () => {
  const { tournamentData, payoutTournamentData, 
    loading } = useTournamentDetails()

  if (loading) return <Preloader />
  return <CreateTournament data={tournamentData} type={"EDIT"} />
}

export default EditTournament
