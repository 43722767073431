import React, { useState } from 'react'
import {
    Row,
    Accordion,
    Table,
} from '@themesberg/react-bootstrap'
import { TournamentDashboardHeader } from '../constants';
import { getDateTime } from '../../../utils/dateFormatter';
import { convertToTimeZone, getFormattedTimeZoneOffset } from '../../../utils/helper';
import { timeZones } from '../../Dashboard/constants';
import { getItem } from '../../../utils/storageUtils';

const TournamentAccordion = ({ activeToggleHeader, list, tournamentData }) => {

    const [activeAccordionKey, setActiveAccordionKey] = useState('');
    const timeZone = getItem("timezone");
    const timezoneOffset = timeZone != null ? timeZones.find(x => x.code === timeZone).value : getFormattedTimeZoneOffset();

    // Dynamically update the headers based on the tournamentData entryCoin
    const TournamentDashboardHeaderModified = TournamentDashboardHeader.filter((header) => {
        if (header.labelKey === 'SC Bet' && tournamentData?.entryCoin !== 'SC') {
            return false;  // Exclude SC Bet column if entryCoin is not SC
        }
        if (header.labelKey === 'GC Bet' && tournamentData?.entryCoin !== 'GC') {
            return false;  // Exclude GC Bet column if entryCoin is not GC
        }
        if (header.labelKey === 'scWinAmount' || header.labelKey === 'gcWinAmount' || header.labelKey === 'Winner') {
            if (activeToggleHeader === "Booted Player") {
                return false;
            }
        }
        return true;
    }).map((header) => {
        if (header.labelKey === 'Score') {
            if (tournamentData?.entryCoin === 'SC') {
                return { ...header, labelKey: 'Score (win SC)' };  // Update header for SC
            }
            if (tournamentData?.entryCoin === 'GC') {
                return { ...header, labelKey: 'Score (win GC)' };  // Update header for GC
            }
        }
        if (header.labelKey === 'SC Bet' && tournamentData?.entryCoin === 'SC') {
            return { ...header, labelKey: 'SC Play' };  // Change header label for SC
        }
        if (header.labelKey === 'GC Bet' && tournamentData?.entryCoin === 'GC') {
            return { ...header, labelKey: 'GC Play' };  // Change header label for GC
        }
        if (header.labelKey === 'scWinAmount') {
            return { ...header, labelKey: 'Rewarded SC' };  // Change header label for GC
        }
        if (header.labelKey === 'gcWinAmount') {
            return { ...header, labelKey: 'Rewarded GC' };  // Change header label for GC
        }
        return header;
    });

    return (
        <Accordion activeKey={activeAccordionKey}>
            <Accordion.Item eventKey={activeToggleHeader}>
                <Accordion.Header onClick={() => setActiveAccordionKey(activeAccordionKey ? '' : activeToggleHeader)}>
                    <Row className="mt-4" style={{ cursor: 'pointer' }}>
                        <h5 className="accordian-heading">
                            <span>{activeToggleHeader}</span>
                        </h5>
                    </Row>
                </Accordion.Header>
                <Accordion.Body>
                    <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
                        <thead className='thead-dark'>
                            <tr>
                                {TournamentDashboardHeaderModified.map((h, idx) => (
                                    <th key={idx}>{h.labelKey} </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>

                            {list?.length > 0 &&
                                list?.map((data) => {
                                    return (
                                        <tr key={data?.userId}>
                                            <td>{data?.userId}</td>
                                            <td>{data?.User.username}</td>
                                            <td>{data?.User.email}</td>
                                            <td>{getDateTime(convertToTimeZone(data?.createdAt, timezoneOffset))}</td>
                                            <td>{data?.score}</td>
                                            <td>
                                                {tournamentData?.entryCoin === 'SC' ? data?.scBet :
                                                    tournamentData?.entryCoin === 'GC' ? data?.gcBet : '-'}
                                            </td>

                                            <td>{(tournamentData?.status === '2' || tournamentData?.status === '3') ? data?.scWinAmount : "-"}</td>
                                            <td>{(tournamentData?.status === '2' || tournamentData?.status === '3') ? data?.gcWinAmount : "-"}</td>

                                            <td>{data?.ggr ? data?.ggr : '-'}</td>
                                            <td>{data?.isWinner ? "Yes" : "No"}</td>
                                        </tr>
                                    );
                                })}
                            {list?.length === 0 && (
                                <tr>
                                    <td colSpan={7} className='text-danger text-center'>
                                        No Data Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default TournamentAccordion